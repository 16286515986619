import React, { Component } from 'react'
import {motion} from 'framer-motion'
import { Navigate } from 'react-router-dom'
import WordCloud from "wordcloud";
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import $ from 'jquery'
// component
import agent from '../../api/Api'
import Header from '../Header/Header'
import { debounce } from 'throttle-debounce'
import Overlay from '../Overlay/Overlay'
// css
import './TeacherDashboard.css'

// const imageOptions = {
//   scale: 5,
//   encoderOptions: 1,
//   backgroundColor: '#4D4D4D',
// }


class TeacherDashboard extends Component {

  constructor(props) {
    super(props)

    this.opt = {
      color: "white",
      rotateRatio: 1,
      rotationSteps: 2,
      minRotation:0,
      maxRotation:300,
      wait: 30,
      weightFactor: (size) => {
        return (Math.sqrt(size) * this.canvas.current.width) / 60;
      },
      fontFamily: "ProximaNova-Regular",
      gridSize: 15,
      backgroundColor: "rgba(30,30,40,0)",
      scale: "sqrt", // linear, log
      spiral: "rectangular",
      // origin: [90, 0],
      // shuffle: false,
    };


    this.state = {
      wordcloud: [],
      list: [],
      // options: {
      //   colors: ['#ffffff'],
      //   enableTooltip: false,
      //   deterministic: true,
      //   fontFamily: 'ProximaNova-Regular',
      //   fontSizes: [10, 60],
      //   fontStyle: 'normal',
      //   fontWeight: 'normal',
      //   padding: 3,
      //   rotations: 2,
      //   rotationAngles: [0, 90],
      //   scale: 'sqrt', // linear, log
      //   spiral: 'rectangular',
      //   transitionDuration: 1000
      // },
      page: '',
      role: '',
      redirect: false,
      redirectTarget: '',
      userInfo: { teacherCode: '', additionalCodes: [] },
      loggedIn: false,
      teacherCodeList: [],
      typeOfComponent: '',
      maskPreviewIndex: '',
      age: '',
      teacherCode: '',
      word: '',
      location: '',
      selectedOptions: [],
      selectedFilterValues: [],
      maskData: [],
      checkedMask: [],
      showDownload: false,
      nextCursor: null,
      isNextPageAvailable: false,
      nextPage: false
    }
    this.handleGetWorkcloud = this.handleGetWorkcloud.bind(this)
    this.handleGetMask = debounce(500, this.handleGetMask.bind(this))
    this.handleGetTeacherCodeList = this.handleGetTeacherCodeList.bind(this)
    this.handleFilterInputChange = this.handleFilterInputChange.bind(this)
    this.handleFilterSelectChange = this.handleFilterSelectChange.bind(this)
    this.handleFilterClear = this.handleFilterClear.bind(this)
    this.handleRemoveSpecificFilter = this.handleRemoveSpecificFilter.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.handleMaskPreview = this.handleMaskPreview.bind(this)
    this.handleMultipleDownload = this.handleMultipleDownload.bind(this)
    this.handleSingleDownload = this.handleSingleDownload.bind(this)
    this.hideOverlay = this.hideOverlay.bind(this)
    this.handleNextPrev = this.handleNextPrev.bind(this)
    this.handleDownload = this.handleDownload.bind(this)
    this.resize = this.resize.bind(this)
    this.canvas = React.createRef();
    // this.handleEditProfile = this.handleEditProfile.bind(this)

  }

  componentDidMount() {
    var loginCheck = JSON.parse(localStorage.getItem('userInfo'))
    // console.log('loginCheck', loginCheck)
    if (loginCheck && loginCheck.role === 'teacher') {
      $('#loader').fadeIn()
      // get word cloud
      this.handleGetWorkcloud(loginCheck.email, loginCheck.parameter)
      // get teacher code list
      this.handleGetTeacherCodeList(loginCheck.email)
      // get all teacher data from remote
      this.handle1GetAllMask(loginCheck.email)
      this.setState({ userInfo: loginCheck, role: loginCheck.role, loggedIn: true })
    } else {
      this.setState({ redirect: true, redirectTarget: '/' })
    }

    const canvas = this.canvas.current;
    this.parent = canvas.parentElement;
    this.handleGetWorkcloud(loginCheck.email,this.state.list);
    this.resize();

    console.log(window.innerWidth);
    if(window.innerWidth <= 768){
      window.addEventListener("resize", this.resize.bind(this));
      this.resize();
    }
  }


  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  resize() {
    const canvas = this.canvas.current;
    const dim = this.parent.getBoundingClientRect();
    if (canvas != null) {
      canvas.width = dim.width;
      canvas.height = dim.height;
      var loginCheck = JSON.parse(localStorage.getItem('userInfo'))
      this.handleGetWorkcloud(loginCheck.email);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.visData !== prevProps.visData) {
      this.handleGetWorkcloud(this.state.list);
    }
  }


  handleGetWorkcloud(emailId, parameter) {
     const param = '/teacher/getTagsWithCount?email=' + emailId + "&&" + parameter
    $('#loader').fadeIn()
    agent.APILIST.getMask(param)
      .then(res => {
        var list = []
        if (res.status === 'SUCCESS') {
          var temp=[]
          Object.keys(res.tagsWithCount).map((key, i) => {
            var list1 = []
            list1.push(key.toUpperCase(), res.tagsWithCount[key])
            temp.push({ text: key.toUpperCase(), value: res.tagsWithCount[key] })
            list.push(list1);
            return 1
          })
          temp?.sort(
            // sorting array by text
            (item1, item2) => {
              if (item1?.text < item2?.text) return -1;
              if (item1?.text > item2?.text) return 1;
              return 0;
            }
          ).sort(
            // sorting array by value
            (item1, item2) => {
              if (item1?.value > item2?.value) return -1;
              if (item1?.value < item2?.value) return 1;
              return 0;
            }
          );
          this.setState({ wordcloud: temp, list: this.state.list })
          const canvas = this.canvas.current;
          var ctx = canvas.getContext("2d");
          ctx.fillStyle = "blue";
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          WordCloud(canvas, { list:list, ...this.opt });
          $('#loader').fadeOut()
        }
      })
      .catch(err => {
        $('#loader').fadeOut()
        console.log(err)
      })

  }


  handle1GetAllMask(emailId) {
    let param = '/mask/getMasksByTeacherId?email=' + emailId

    if (this.state.nextPage) {
      $('#loader').fadeIn()
      param += 'cursor=' + this.state.nextCursor
    }

    agent.APILIST.getMask(param)
      .then(res => {
        if (res.status === 'SUCCESS') {
          if (this.state.nextPage) {
            const data = this.state.maskData
            const newData = [...data, ...res.data.maskDetails]
            this.setState({ maskData: newData }, () => {
            })
          } else {
            this.setState({ maskData: res.data.maskDetails })
          }
          this.setState({ nextCursor: res.data.nextCursor, isNextPageAvailable: res.data.isNextPageAvailable, nextPage: false })
          $('#loader').fadeOut()
        }
      })
      .catch(err => {
        $('#loader').fadeOut()
        console.log(err)
      })
  }

  handleGetTeacherCodeList(email) {
    let url = '/teacher/getAllTeacherCodesById?email=' + email
    agent.APILIST.getMask(url)
      .then(res => {
        if (res.status === 'SUCCESS') {
          var temp = res.codeWithName ? res.codeWithName : {}
          var defaultCode = { Default: res?.teacherCode }
          var newTemp = $.extend(defaultCode, temp)
          this.setState({ teacherCodeList: newTemp })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleGetMask(obj) {
    const { teacherCode } = this.props?.match?.params || {teacherCode: ''}
    this.setState({ teacherCode })
    var getMaskUrl = ''
    if (this.props?.match?.url === '/teacher/dashboard') getMaskUrl = '/mask/getMasksByTeacherId?email=' + this.state.userInfo.email // TODO update url with teacher code
    else getMaskUrl = '/mask/gallery' // TODO update url with teacher code

    let param = ''

    if (this.state.nextPage) {
      $('#loader').fadeIn()
      param += 'cursor=' + this.state.nextCursor + '&&'
    }

    for (let j = 0; j < obj.length; j++) {
      param += obj[j].name + '=' + (obj[j].name === 'location' || obj[j].name === 'word' ? obj[j].value.toLowerCase() : obj[j].name === 'teacherCode' ? this.state.teacherCodeList[obj[j].value] : obj[j].value)
      if (j < obj.length - 1) {
        param += '&&'
      }
    }
    console.log('param', param)
    if (param) {
      if (this.props?.match?.url === '/teacher/dashboard') param = '&&' + param
      else param = '?' + param
    }

    this.handleGetWorkcloud(this.state.userInfo.email, param);

    agent.APILIST.getMask(getMaskUrl + param)
      .then(res => {
        if (res.status === 'SUCCESS') {
          if (this.state.nextPage) {
            const data = this.state.maskData
            const newData = [...data, ...res.data.maskDetails]
            this.setState({ maskData: newData }, () => {
            })
          } else {
            this.setState({ maskData: res.data.maskDetails })
          }
          this.setState({ nextCursor: res.data.nextCursor, isNextPageAvailable: res.data.isNextPageAvailable, nextPage: false })
          $('#loader').fadeOut()
        }
      })
      .catch(err => {
        console.log(err)
        $('#loader').fadeOut()
      })
  }

  handleFilterInputChange(event) {
    if (this.state.showDownload) {
      $('.checkMask').prop('checked', false)
      this.setState({ showDownload: false, checkedMask: [] })
    }
    const { name, value } = event.target
    // console.log(name, value)
    const obj = this.state.selectedFilterValues
    const options = this.state.selectedOptions
    let verify = false
    if (name === 'age') {
      if (value.length <= 2 && value[0] !== '0') {
        const re = /^[0-9]+$/
        if (re.test(value) || value === '') {
          this.setState({ [name]: value })
          verify = true
        } else {
          verify = false
        }
      }
    } else if (name === 'location') {
      const re = /^[0-9A-Za-z ]+$/
      if (re.test(value) || value === '') {
        this.setState({ [name]: value })
        verify = true
      } else {
        verify = false
      }
    } else if (name === 'word') {
      const re = /^[0-9A-Za-z ]+$/
      if (re.test(value) || value === '') {
        this.setState({ [name]: value })
        verify = true
      } else {
        verify = false
      }
    }

    // console.log('ver', verify)
    if (verify) {
      let done = false
      for (let k = 0; k < obj.length; k++) {
        if (obj[k].name === name) {
          obj[k].value = value
          done = true
        }
      }

      if (!done) {
        obj.push({ name: name, value: value })
        options.push(name)
      }
      if (value.length === 0) {
        options.splice(options.indexOf(name), 1)
        obj.splice(options.indexOf(name), 1)
      }
      this.setState({ selectedFilterValues: obj, selectedOptions: options })
      this.handleGetMask(obj)


    }
  }

  handleFilterSelectChange(event) {
    if (this.state.showDownload) {
      $('.checkMask').prop('checked', false)
      this.setState({ showDownload: false, checkedMask: [] })
    }
    const { name, value } = event.target
    const options = this.state.selectedOptions
    const obj = this.state.selectedFilterValues
    if (value) {
      if (options.indexOf(name) >= 0) {
        // add selected filter from array
        obj[options.indexOf(name)].value = value
        this.setState({ selectedFilterValues: obj })
        this.handleGetMask(obj)
      } else {
        // add selected option and selected filter to array
        options.push(name)
        obj.push({ name: name, value: value })
        this.setState({ selectedFilterValues: obj, selectedOptions: options })
        this.handleGetMask(obj)
      }
    } else {
      const removeOption = this.state.selectedOptions.indexOf(name)
      // remove selected filter from array
      const obj = this.state.selectedFilterValues
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].name === name) {
          obj.splice(i, 1)
        }
      }
      // remove selected option from array
      for (let j = 0; j < options.length; j++) {
        options.splice(removeOption, 1)
      }
      this.setState({ selectedFilterValues: obj, selectedOptions: options })
      this.handleGetMask(obj)
    }
  }

  handleCheckboxChange(event) {
    const { checked } = event.target
    const temp = this.state.checkedMask
    // console.log(event.target.dataset.id, event.target.dataset.index, checked)
    const index = event.target.dataset.index

    if (checked) {
      temp.push(this.state.maskData[index])
      this.setState({ checkedMask: temp })
    } else {
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].maskId === parseInt(event.target.dataset.id)) {
          temp.splice(i, 1)
          this.setState({ checkedMask: temp })
        }
      }
    }

    if (temp.length > 0) {
      this.setState({ showDownload: true })
    } else {
      this.setState({ showDownload: false })
    }
    // console.log('temp', temp)
  }

  handleFilterClear() {
    if (this.state.showDownload) {
      $('.checkMask').prop('checked', false)
      this.setState({ showDownload: false, checkedMask: [] })
    }

    this.setState({
      selectedFilterValues: [],
      selectedOptions: [],
      word: '',
      age: '',
      location: ''
    })
    const selectTags = document.getElementsByTagName('select')
    for (var i = 0; i < selectTags.length; i++) {
      selectTags[i].selectedIndex = 0
    }
    this.handleGetMask('')
  }

  handleRemoveSpecificFilter(name) {
    if (this.state.showDownload) {
      $('.checkMask').prop('checked', false)
      this.setState({ showDownload: false, checkedMask: [] })
    }

    const options = this.state.selectedOptions
    const obj = this.state.selectedFilterValues

    // remove selected filter from array
    for (let i = 0; i < obj.length; i++) {
      if (obj[i].name === name) {
        obj.splice(i, 1)
      }
    }

    $('#' + name).val('')
    this.setState({ [name]: '' })

    // remove selected option from array
    const removeOption = options.indexOf(name)
    options.splice(removeOption, 1)

    this.setState({ selectedFilterValues: obj, selectedOptions: options })
    this.handleGetMask(obj)
  }

  handleNextPrev(flag) {
    const obj = this.state.selectedFilterValues
    if (flag === 'N' && this.state.isNextPageAvailable) {
      this.setState({ nextPage: true }, () => {
        this.handleGetMask(obj)
      })
    }
  }

  handleMaskPreview(index) {
    this.setState({ typeOfComponent: 'MaskPreview', maskPreviewIndex: index })
  }

  hideOverlay() {
    this.setState({ typeOfComponent: '' })
  }

  handleSingleDownload(guid) {
    const url = 'https://storage.googleapis.com/ever-forward-club.appspot.com/images/' + guid + '.png'
    saveAs(url, guid + '.png')
  }

  handleMultipleDownload() {
    var zip = new JSZip()
    var count = 0
    var pages = this.state.checkedMask

    pages.forEach(file => {
      fetch('https://storage.googleapis.com/ever-forward-club.appspot.com/images/' + file.maskGuid + '.png', { method: 'GET' }).then(response => response.blob())
        .then(response => {
          // console.log(response)
          zip.file(file.maskGuid + '.png', response, { binary: true })
          ++count
          if (count === pages.length) {
            zip.generateAsync({ type: 'blob' })
              .then((content) => {
                saveAs(content, 'maskDownload.zip')
                $('.checkMask').prop('checked', false)
                this.setState({ showDownload: false, checkedMask: [] })
                this.handleFilterClear()
              })
          }
        })
        .catch(error => {
          console.log(error)
        })
    })
  }

  handleDownload() {
    var canvas = this.canvas.current
    var url = canvas.toDataURL("image/jpeg");
    var link = document.createElement('a');
    link.download = 'filename.jpg';
    link.href = url;
    link.click();
  }

  render() {
    if (this.state.redirect) {
     return <Navigate to={this.state.redirectTarget} replace />
    }
    const componentOverlay = this.state.typeOfComponent === 'MaskPreview'
      ? <Overlay typeOfComponent='MaskPreview' size='img' hideOverlay={this.hideOverlay.bind(this)} maskData={this.state.maskData} maskPreviewIndex={this.state.maskPreviewIndex} />
      : ''
    return (
      <div id='gallery'>
        {componentOverlay}
        <Header loggedIn={this.state.loggedIn} role={this.state.role} onHandleGetTeacherCodeList={this.handleGetTeacherCodeList} />
        <div className='gallery-outer-blk noselect'>
          <div className='gallery-inner-blk'>
            <div className='gallery-top-blk'>
              <div className='gallery-top-row'>
                <div className='title-blk'>
                  <div>EDUCATOR VIEW</div>
                  <a className='title-link' href='/gallery'>Go to Mask Gallery</a>
                </div>
                <div className='filter-outer-blk'>
                  <div className='filter-inner-blk'>
                    <div className='filter-blk teacher'>
                      <label className='filter-title'>filter by</label>
                      <div className='hr-line' />
                      <select onChange={this.handleFilterSelectChange} name='teacherCode' id='teacherCode'>
                        <option value=''>Your Code</option>
                        {Object.keys(this.state.teacherCodeList).map((key, i) => (
                          <option key={i} value={key}>{key}</option>
                        ))}
                      </select>
                      <input type='text' onChange={this.handleFilterInputChange} value={this.state.age} name='age' placeholder='Age' id='age' />
                      <select onChange={this.handleFilterSelectChange} name='gender' id='gender'>
                        <option value=''>Gender</option>
                        <option value='Male'>Male</option>
                        <option value='Female'>Female</option>
                        <option value='Other'>Other</option>
                        <option value='Prefer not to answer'>Prefer not to answer</option>
                      </select>
                      <input type='text' maxLength='25' onChange={this.handleFilterInputChange} value={this.state.location} name='location' placeholder='City' id='location' />
                    </div>
                    <div className='search-blk'>
                      <img src={process.env.PUBLIC_URL + '/assets/images/icons/Search-small.png'} alt='Search-Icon' />
                      <input type='text' placeholder='Search with any keywords' name='word' maxLength='25' onChange={this.handleFilterInputChange} value={this.state.word} />
                    </div>
                  </div>
                </div>
                <div className='clr' />
              </div>

              <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:0.3}}>
                <div className={this.state.selectedOptions.length ? 'gallery-bottom-row' : 'gallery-bottom-row hide'}>
                  <p className='result-found'>{this.state.maskData.length} search results for</p>
                  <div className='selected-filter-blk'>
                    {this.state.selectedFilterValues.map((list, index) => {
                      return (
                        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:0.3}} key={index}>
                          <div className='selected-filter-option'>
                            {/* {list.name === 'age' ? <span className='label'>Age</span> : ''} */}
                            <span className='label'>{list.name}</span>
                            <span>{list.value}</span>
                            <img className='close-img' src={process.env.PUBLIC_URL + '/assets/images/icons/Close.png'} alt='close' onClick={() => this.handleRemoveSpecificFilter(list.name)} />
                          </div>
                        </motion.div>
                      )
                    })}
                  </div>
                  <div className='clear-filter' onClick={this.handleFilterClear}>Clear All</div>
                </div>
              </motion.div>
            </div>
            <div className='word-cloud-outer' id='wordcloud'>
              <div className='words-container'>
                <div className="cloud-download" onClick={() => this.handleDownload()} ><img className='download-img' src={process.env.PUBLIC_URL + '/assets/images/icons/download-plain.png'} alt='download' /> <p style={{ marginLeft: '5px' }}>Download </p></div>
                <div style={{ height: "100%", width: "100%" }}>
                  <canvas ref={this.canvas}/>
                </div>
              </div>
              
              <div class="word-cloud-count" id="wordscount">
                <div className='words-count-header'>Words Count</div>
                <ul className='words-count-list'>
                  {this.state?.wordcloud?.map((ele,index)=>
                    <li className='list-element' key={"list-element"+index}>
                      {ele?.text}-{ele?.value}
                    </li>)
                  }
                </ul>                
              </div>
            </div>
              {/* <ReactWordcloud options={this.state.options} words={this.state.wordcloud} /> */}
            <div className='gallery-content-outer'>
              <div className='gallery-content-blk'>
                {this.state.maskData.map((list, index) => {
                  return (<div className='mask-blk' key={index}>
                    <div className='mask-top'>
                      {/* checkbox */}
                      {/* <div className='position-align'>
                        <div className='select-and-share-blk'>
                          <div className='round'>
                            <input type='checkbox' className='checkMask' id={'checkbox-' + index} data-id={list.maskId} data-index={index} name={'checkboxId-' + index} onChange={this.handleCheckboxChange} />
                            <label htmlFor={'checkbox-' + index} />
                          </div>
                        </div>
                      </div> */}
                      <img src={'https://storage.googleapis.com/ever-forward-club.appspot.com/images/' + list.maskGuid + '.png'} alt='mask' onClick={() => this.handleMaskPreview(index)} />
                    </div>
                    <div className='mask-bottom'>
                      <div className='mask-info'><span>{list.age} Yrs</span> | <span title={list.gender.length > 15 ? list.gender : ''}>{list.gender.length > 15 ? list.gender.substring(0, 15) + '...' : list.gender}</span> | <span style={{ textTransform: 'capitalize' }} title={list.city.length > 15 ? list.city : ''}>{list.city.length > 15 ? list.city.substring(0, 15) + '...' : list.city}</span></div>
                      {/* download */}
                      {/* <div onClick={() => this.handleSingleDownload(list.maskGuid)}><img className='download-img' src={process.env.PUBLIC_URL + '/assets/images/icons/download-plain.png'} alt='download' /></div> */}
                    </div>
                  </div>)
                })}
                {this.state.maskData.length === 0 ? <div><h1 style={{ textAlign: 'center', color: '#FFF' }}>No Mask Found</h1></div> : ''}
                <div className='pagination-outer'>
                  {this.state.isNextPageAvailable
                    ? (<div className='pagination-inner'>
                      <div className='next-blk' onClick={() => this.handleNextPrev('N')}><p>Show more</p></div>
                    </div>)
                    : ''}
                </div>
              </div>
            </div>
            <div className='footer'>
              <p>© everforwardclub.org - 2020</p>
            </div>
            {/* <div className={this.state.showDownload ? 'showdownload-outer show' : 'showdownload-outer hide'}>
              <div className='showdownload-inner'>
                <span className='selected-count-title'>{this.state.checkedMask.length} Mask{this.state.checkedMask.length > 1 ? 's' : ''} Selected</span>
                <div className='hr-line' />
                <span className='selected-title'>Download selected Mask{this.state.checkedMask.length > 1 ? 's' : ''}</span>
                <div className='download-btn' onClick={this.handleMultipleDownload}>
                  <img className='download-img' src={process.env.PUBLIC_URL + '/assets/images/icons/download-solid.png'} alt='download' />
                  <span className='download-label'>Download</span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    )
  }
}

export default TeacherDashboard
