import React, { Component} from 'react'
import $ from 'jquery'
// css
import './TakeTour.css'

class TakeTour extends Component {
  constructor (props) {
    super(props)
    this.state = {
      steps: [],
      stepsLap: [{
        class: 'row1',
        selector: '.tool-blk',
        content: <p>Use tools to draw</p>,
        position: {top: 65, left: 10},
        gettop: true,
        getleft: false,
        style: {background: '#FFF'},
        arrow: {top: -3, background: '#FFF'}
      }, {
        class: 'row1',
        selector: '.size-blk',
        content: <p>Choose size <br />of your brush</p>,
        position: {top: 65, left: -20},
        gettop: true,
        getleft: false,
        style: {background: '#FFF'},
        arrow: {top: -3, background: '#FFF'}
      }, {
        class: 'row1',
        selector: '.color-blk',
        content: <p>Choose Colors <br />for your brush</p>,
        position: {top: 65, left: -30},
        gettop: true,
        getleft: false,
        style: {background: '#FFF'},
        arrow: {top: -3, background: '#FFF'}
      }, {
        class: 'row1',
        selector: '.text-blk',
        content: <p>Customize your text.<br />Click and drag your<br />text anywhere within <br />Mask area.</p>,
        position: {top: 65, left: 40},
        gettop: true,
        getleft: false,
        style: {background: '#FFF'},
        arrow: {top: -3, background: '#FFF'}
      }, {
        class: 'row1',
        selector: '.draw-submit-blk .draw-submit-btn',
        content: <p>Submit your mask once <br />you are done</p>,
        position: {top: 65, left: 0},
        gettop: true,
        getleft: false,
        style: {background: '#FFF008'},
        arrow: {top: -3, background: '#FFF008'}
      }, {
        class: 'row2',
        selector: '#canvasInAPerfectWorld',
        content: <div className='sidebyside'><img src={process.env.PUBLIC_URL + '/assets/images/icons/Mask.png'} alt='mask' /><p>Use this area to <br />draw your mask</p></div>,
        position: {top: ($(window).height() / 2) - 50, left: ($(window).width() / 2) - 250},
        gettop: false,
        getleft: true,
        style: {background: '#FF8787'},
        arrow: {bottom: -3, background: '#FF8787'}
      }, {
        class: 'row2',
        selector: '#canvasInAPerfectWorld',
        content: <div className='sidebyside'><img src={process.env.PUBLIC_URL + '/assets/images/icons/Brush.png'} alt='brush' /><p>Use this area to<br /> write your qualities</p></div>,
        position: {top: ($(window).height() / 2) - 50, left: ($(window).width() / 2) + 250},
        gettop: false,
        getleft: true,
        style: {background: '#FF8787'},
        arrow: {bottom: -3, background: '#FF8787'}
      }, {
        class: 'row3',
        selector: '.front-bottom',
        content: <p>Type your qualities and<br /> characteristics of yourself that<br /> you gladly let others see.</p>,
        position: {top: -80, left: ($(window).width() / 2) - 250},
        gettop: true,
        getleft: true,
        style: {background: '#FFF'},
        arrow: {bottom: -3, background: '#FFF'}
      }, {
        class: 'row3',
        selector: '.back-bottom',
        content: <p>What are the qualities and<br /> characteristics of yourself <br />that you dont let others see.</p>,
        position: {top: -80, left: ($(window).width() / 2) + 250},
        gettop: true,
        getleft: true,
        style: {background: '#FFF'},
        arrow: {bottom: -3, background: '#FFF'}
      }],
      stepsTab: [{
        class: 'row1',
        selector: '.size-blk',
        content: <p>Use tools to draw,<br /> Choose size of your brush and <br /> Choose Colors for your brush </p>,
        position: {top: 65, left: 250},
        gettop: true,
        getleft: true,
        style: {background: '#FFF'},
        arrow: {top: -3, background: '#FFF'}
      }, {
        class: 'row1',
        selector: '.text-blk',
        content: <p>Customize your text.<br />Click and drag your<br />text anywhere within<br /> Mask area.</p>,
        position: {top: 65, left: 0},
        gettop: true,
        getleft: false,
        style: {background: '#FFF'},
        arrow: {top: -3, background: '#FFF'}
      }, {
        class: 'row1',
        selector: '.draw-submit-blk .draw-submit-btn',
        content: <p>Submit your <br /> mask once <br />you are done</p>,
        position: {top: 65, left: $(window).width() - 100},
        gettop: true,
        getleft: true,
        style: {background: '#FFF008'},
        arrow: {top: -3, background: '#FFF008'}
      }, {
        class: 'row2',
        selector: '#canvasInAPerfectWorld',
        content: <div className='sidebyside'><img src={process.env.PUBLIC_URL + '/assets/images/icons/Mask.png'} alt='mask' /><p>Use this area to <br />draw your mask</p></div>,
        position: {top: ($(window).height() / 2) - 120, left: ($(window).width() / 2) - 150},
        gettop: true,
        getleft: true,
        style: {background: '#FF8787'},
        arrow: {bottom: -3, background: '#FF8787'}
      }, {
        class: 'row2',
        selector: '#canvasInAPerfectWorld',
        content: <div className='sidebyside'><img src={process.env.PUBLIC_URL + '/assets/images/icons/Brush.png'} alt='brush' /><p>Use this area to<br /> write your qualities</p></div>,
        position: {top: ($(window).height() / 2) - 120, left: ($(window).width() / 2) + 150},
        gettop: true,
        getleft: true,
        style: {background: '#FF8787'},
        arrow: {bottom: -3, background: '#FF8787'}
      }, {
        class: 'row3',
        selector: '.front-bottom',
        content: <p>Type your qualities and<br /> characteristics of yourself that<br /> you gladly let others see.</p>,
        position: {top: $(window).height() - 200, left: ($(window).width() / 2) - 150},
        gettop: true,
        getleft: true,
        style: {background: '#FFF'},
        arrow: {bottom: -3, background: '#FFF'}
      }, {
        class: 'row3',
        selector: '.back-bottom',
        content: <p>What are the qualities and<br /> characteristics of yourself <br />that you dont let others see.</p>,
        position: {top: $(window).height() - 200, left: ($(window).width() / 2) + 150},
        gettop: true,
        getleft: true,
        style: {background: '#FFF'},
        arrow: {bottom: -3, background: '#FFF'}
      }],
      stepsMob: [{
        class: 'row1',
        selector: '.draw-submit-blk .draw-submit-btn',
        content: <p>Submit your mask <br />once you are done</p>,
        position: {top: 10, left: $(window).width() - 200},
        gettop: true,
        getleft: true,
        style: {background: '#FFF008'},
        arrow: {top: 27, left: '96%', background: '#FFF008'}
      }, {
        class: 'row2',
        selector: '.text-blk',
        content: <p>Use the above tools to draw. <br />Customize your text.<br />Click and drag your<br />text anywhere within Mask area.</p>,
        position: {top: 130, left: ($(window).width() / 2) - 50},
        gettop: true,
        getleft: true,
        style: {background: '#FFF'},
        arrow: {top: -3, background: '#FFF'}
      }, {
        class: 'row2',
        selector: '#canvasInAPerfectWorld',
        content: (<div><div className='sidebyside'><img src={process.env.PUBLIC_URL + '/assets/images/icons/Mask.png'} alt='mask' /><p>Use this area to <br />draw your mask</p></div>
          <div className='sidebyside'><img src={process.env.PUBLIC_URL + '/assets/images/icons/Brush.png'} alt='brush' /><p>and<br /> write your qualities</p></div>
        </div>),
        position: {top: ($(window).height() / 2) - 30, left: ($(window).width() / 2) - 50},
        gettop: true,
        getleft: true,
        style: {background: '#FF8787'},
        arrow: {top: -3, background: '#FF8787'}
      }, {
        class: 'row3',
        selector: '.front-bottom',
        content: (<div><p>Type your qualities and characteristics of <br />yourself that  you gladly let others see.</p>
          <p>and</p>
          <p>What are the qualities and characteristics of <br />yourself that you dont let others see.</p>
        </div>),
        position: {top: $(window).height() - 180, left: ($(window).width() / 2) - 50},
        gettop: true,
        getleft: true,
        style: {background: '#FFF'},
        arrow: {bottom: -3, background: '#FFF'}
      }],
      procedure: [{ number: 1,
        desc: 'Draw a mask on the left side.'
      }, {
        number: 2,
        desc: 'Write 3 words on the Front of Mask that represent qualities you let people see'
      }, {
        number: 3,
        desc: 'Write 3 words on the Back of Mask that represent the things you don\'t usually let people see.'
      }, {
        number: 4,
        desc: 'Click Submit'
      }]
    }
    this.handleResize = this.handleResize.bind(this)
  }

  componentDidMount () {
    // window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  handleResize () {
    let devWidth = $(window).width()
    // let devHeight = $(window).height()
    let obj = null
    // alert('res')
    if (devWidth >= 1024) {
      obj = this.state.stepsLap
      // alert('lap')
    } else if (devWidth > 768 && devWidth < 1024) {
      // alert('tab')
      obj = this.state.stepsTab
    } else {
      // alert('mob')
      obj = this.state.stepsMob
    }
    this.setState({steps: obj})
  }

  render () {
    return (
      <div id='taketour'>
        <div className='taketour-outer'>
          <div className='sidebar-outer'>
            <div className='steps-outer'>
              <div className='sidebar-title-blk noselect'><p className='title'>Instructions</p></div>
              { this.state.procedure.map((list, index) => {
                return (<div className='step-blk noselect' key={index}>
                  <div className='step-number'><span>{list.number}</span></div>
                  <div className='step-desc'><p>{list.desc}</p></div>
                  {this.state.procedure.length - 1 !== index ? <div className='hr-line' /> : ''}
                </div>)
              })}
            </div>
          </div>
          <div className='tour-main-content'>
            { this.state.steps.map((list, index) => {
              let pos = $(list.selector).offset()
              return (<span className={'note-blk noselect ' + list.class} style={{top: list.gettop ? list.position.top : pos.top + list.position.top, left: list.getleft ? list.position.left : pos.left + list.position.left, background: list.style.background}} key={index}>
                <div className='arrow-blk' style={list.arrow} />
                {/* <p>{pos.left}</p>
                <p>{list.position.left}</p>
                <p>{pos.left + list.position.left}</p> */}
                {list.content}
              </span>
              )
            })}
            <div className='skip-outer'>
              <div className='skip-blk noselect'>
                <p>Skip tips and instructions?</p>
                <div className='skip-btn' onClick={this.props.closeTour}>skip</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TakeTour
