import React, { Component } from 'react'
// css
import './SignUpSuccess.css'

class SignUpSuccess extends Component {
  render () {
    return (
      <div id='signup-success'>
        <div className='form-succes-outer-blk noselect'>
          <p className='title'>Thank you!</p>
          <div className='desc'>
            <p>Your registration is completed.</p>
            <p>Check your email for unique code and URL </p>
            <p>to share with your students.</p>
          </div>
          <div className='hr-line' />
          <a className='signin-btn' href='/auth/teacher/signin'>
            <p>Sign In</p>
          </a>
          {/* <a className='draw-btn' href='/drawboard'>
            <img className='mask-img' src={process.env.PUBLIC_URL + 'assets/images/icons/mask.png'} alt='mask' />
            <p>draw your mask </p>
          </a>
          <a className='gallery-btn' href='/gallery'>
            <p>go to mask Gallery </p>
            <img className='arrow-black-right' src={process.env.PUBLIC_URL + 'assets/images/icons/arrow-black-right.svg'} alt='arrow' />
          </a> */}
        </div>
      </div>

    )
  }
}

export default SignUpSuccess
