import React, { Component} from 'react'
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon} from 'react-share'
// css
import './MaskSuccess.css'
// const
const title = 'Ever Forward Club'

class MaskSuccess extends Component {
  constructor (props) {
    super(props)
    this.state = {
      shareUrl: '',
      copySuccess: ''
    }
  }

  componentDidMount () {
    console.log('share', this.props.param.maskGuid)
    let url = 'https://storage.googleapis.com/ever-forward-club.appspot.com/images/' + this.props.param.maskGuid + '.png'
    this.setState({shareUrl: url})
  }

  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand('copy');
  };

  render () {
    return (
      <div id='masksuccess'>
        <div className='masksuccess-outer noselect'>
          <p className='title'>Thank you!</p>
          <p className='desc'>Your mask has been submitted.</p>
          <p className='desctext'>If you or anyone you know needs support, the NAMI Helpline at 1-800-950-NAMI (6264) can provide information on how to find various mental health professionals and resources in your area. </p>
          <p className='desctext'>If you are in crisis or are experiencing difficult or suicidal thoughts, call the National Suicide Hotline at 1-800-273 TALK (8255). If you’re uncomfortable talking on the phone, you can also text NAMI to 741-741 to be connected to a free, trained crisis counselor on the Crisis Text Line.</p>
          <div className='btn-blk'>
            <a href='/gallery' className='go-to-gallery-btn'>go to gallery</a>
            <a href='/drawboard' className='draw-again-btn'>draw again</a>
          </div>
          </div>
          
          <img className='seperation' alt='' src='https://www.pngplay.com/wp-content/uploads/8/Vertical-Line-No-Background.png'/>
          <div className='share-outer'>
          <p className='title'>Share with Your Community!</p>
          <p className='desc'>Use the following links to share your mask or invite others.</p>
          <p className='desctext'>Invite someone else to create a mask with tis link </p>
            <div className='share-blk'>
            <div className='copy-blk'>
        {
         document.queryCommandSupported('copy') &&
          <>
            <input className='text-link'
            ref={(textarea) => this.textArea = textarea}
            value='https://millionmask.org/'
          />
            <button className='copy-link' onClick={this.copyToClipboard}>Copy Link</button> 
            {this.state.copySuccess}
          </>
        }
      </div>
      <div className='share-box'>
              <div className='share-label'>Share your Mask on Social Media</div>
              <div className='icon-link'>
              <FacebookShareButton url={this.state.shareUrl} quote={title} className='button'>
                <FacebookIcon size={30} round />
              </FacebookShareButton>
              <TwitterShareButton url={this.state.shareUrl} quote={title} className='button'>
                <TwitterIcon size={30} round />
              </TwitterShareButton>
              <LinkedinShareButton url={this.state.shareUrl} quote={title} className='button'>
              <LinkedinIcon size={30} round/>
              </LinkedinShareButton>
              <a target='_blank'  rel="noopener noreferrer" href='https://www.instagram.com/accounts/login/'>
                <img className='instaimg'alt='instagram' src="http://swchi.org/wp-content/uploads/2019/09/349-3493616_instagram-circle-logo-transparent-hd-png-download.jpg" />
              </a>
              </div>
              </div>
            </div>
          </div>
        
      </div>
    )
  }
}

export default MaskSuccess
