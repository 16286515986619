import React from 'react';
import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import $ from 'jquery';
import { toast } from 'react-toastify';
import Toaster from '../components/Toaster/Toaster';

const superagent = superagentPromise(_superagent, global.Promise);
// dev
// const API_ROOT = 'https://v5-dot-ever-forward-club.el.r.appspot.com' // TODO update to dev-dot
// prod
// const API_ROOT = 'https://v4-dot-ever-forward-club.appspot.com'
// millionmask version
const API_ROOT =
  'https://million-live-v3-dot-ever-forward-club.el.r.appspot.com';

const responseBody = (res) => res.body;

const notifyError = (data) => {
  $('#sub-btn').css({ background: '#64A543', 'pointer-events': 'visible' });
  $('#subscribe').css({ cursor: 'default' });
  toast.dismiss();
  toast(<Toaster title='Error' content={data} status='error' />);
  setTimeout(() => {
    toast.dismiss();
  }, 3000);
};

const handle = (res) => {
  if (res.status === 400 || res.status === 401) {
    return res;
  } else if (res.status === 404) {
    notifyError('404 URL not Found.Please check the server');
  } else if (res.status === 501) {
    notifyError('Internal server error');
  }
};

const imgPlugin = (req) => {
  req.set('Content-Type', 'image/png');
};

const requests = {
  get: (url) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .withCredentials()
      .on('error', handle)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .withCredentials()
      .on('error', handle)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .withCredentials()
      .on('error', handle)
      .then(responseBody),
  imgupload: (url, body) =>
    superagent
      .put(`${url}`)
      .use(imgPlugin)
      .send(body)
      .on('error', handle)
      .then(responseBody),
};

const APILIST = {
  teacherSignup: (path, params) => {
    return requests.post(path, params);
  },
  teacherSignin: (path, params) => {
    return requests.post(path, params);
  },
  getPresignedUrl: (path) => {
    return requests.get(path);
  },
  getTeacherCode: (path) => {
    return requests.get(path);
  },
  uploadImg: (path, params) => {
    return requests.imgupload(path, params);
  },
  maskSubmit: (path, params) => {
    return requests.post(path, params);
  },
  getMask: (path) => {
    return requests.get(path);
  },
  forgotPassword: (path) => {
    return requests.get(path);
  },
  resetPassword: (path) => {
    return requests.post(path);
  },
  getDownloadURL: (path) => {
    return requests.get(path);
  },
  startSync: (path) => {
    return requests.get(path);
  },
  checkSync: (path) => {
    return requests.get(path);
  },
};

export default {
  APILIST: APILIST,
};
