import React, { Component } from 'react'
// css
import './DownloadHeader.css'

class Header extends Component {
  render () {
    return (
      <div id='download-header'>
        <div className='header-outer-blk noselect'>
          <div className='download-logo-blk'>
            <a href='/'>
              <img src={process.env.PUBLIC_URL + '/assets/images/logo.svg'} className='download-logo-img' alt='logo' />
            </a>
            <div className='hr-line' />
            <p>Download</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Header
