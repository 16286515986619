import React, { Component } from 'react'
import { toast } from 'react-toastify'
import $ from 'jquery'
// component
import Toaster from '../Toaster/Toaster'
import agent from '../../api/Api'
// css
import './ChangePassword.css'

class ChangePassword extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userInfo: {},
      currentPwd: '',
      currentPwdType: 'password',
      newPwd: '',
      newPwdType: 'password',
      confirmPwd: '',
      confirmPwdType: 'password'
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitKeyPress = this.handleSubmitKeyPress.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handlePwdShowHide = this.handlePwdShowHide.bind(this)
  }

  handlePwdShowHide (name, type) {
    console.log(name)
    this.setState({ [name]: type === 'password' ? 'text' : 'password' })
  }

  handleInputChange (event) {
    const { name, value } = event.target
    this.setState({ [name]: value })
    if (value.length > 0) {
      $('#' + name).css({ border: '1px solid #ddd' })
    } else {
      $('#' + name).css({ border: '1px solid red' })
    }
  }

  handleSubmit () {
    $('#loader').fadeIn()
    let param = {
      currentPwd: this.state.currentPwd,
      newPwd: this.state.newPwd,
      confirmPwd: this.state.confirmPwd
    }

    let count = 0
    for (var key in param) {
      if (param[key]) {
        $('#' + key).css({ border: '1px solid #ddd' })
        count += 1
      } else {
        $('#' + key).css({ border: '1px solid red' })
      }
    }

    if (param.currentPwd && param.newPwd && param.currentPwd === param.newPwd) {
      $('#loader').fadeOut()
      toast.dismiss()
      toast(<Toaster title='Change password' content='New and current password should not be same' status='error' />)
      setTimeout(function () { toast.dismiss() }, 3000)
    } else if (param.newPwd !== param.confirmPwd) {
      $('#loader').fadeOut()
      toast.dismiss()
      toast(<Toaster title='Change password' content='New and confirm password not match' status='error' />)
      setTimeout(function () { toast.dismiss() }, 3000)
    }

    // alert(count)
    if (count === 3 && param.currentPwd !== param.newPwd && param.newPwd === param.confirmPwd) {
      var newParam = {
        email: this.state.userInfo.email,
        newPassword: param.newPwd,
        oldPassword: param.currentPwd
      }
      agent.APILIST.teacherSignin('/teacher/changePassword', newParam)
        .then(res => {
          if (res.status === 'SUCCESS') {
            $('#loader').fadeOut()
            this.props.hideOverlay()
            toast.dismiss()
            toast(<Toaster title='Teacher Login' content={res.message} status='error' />)
            setTimeout(function () { toast.dismiss() }, 3000)
          }
        })
        .catch(err => {
          let res = JSON.parse(err.response.text)
          toast.dismiss()
          toast(<Toaster title='Change password' content={res.message} status='error' />)
          setTimeout(function () { toast.dismiss() }, 3000)
          $('#loader').fadeOut()
        })
    }
  }

  handleSubmitKeyPress (event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleSubmit()
    }
  }

  componentDidMount () {
    var loginCheck = JSON.parse(localStorage.getItem('userInfo'))
    // console.log('loginCheck', loginCheck)
    if (loginCheck) {
      this.setState({ userInfo: loginCheck })
    }
  }

  render () {
    return (
      <div id='chg-form'>
        <div className='chg-outer-blk noselect'>
          <div className='chg-blk'>
            <div className='title-blk'>
              <p className='chg-title'>Change Password</p>
            </div>
            <div className='vertical-field-group'>
              <div className='label-input'>
                <label>current password*</label>
                <br />
                <input type={this.state.currentPwdType} autoComplete='off' name='currentPwd' id='currentPwd' placeholder='Enter current password' value={this.state.currentPwd} onChange={this.handleInputChange} onKeyPress={this.handleSubmitKeyPress} />
                <span className='pwdShow' onClick={() => this.handlePwdShowHide('currentPwdType', this.state.currentPwdType)}>
                  {this.state.currentPwdType === 'text'
                    ? <img src={process.env.PUBLIC_URL + '/assets/images/icons/ic_hidden.svg'} className='show-hide-icon' alt='eye' />
                    : <img src={process.env.PUBLIC_URL + '/assets/images/icons/ic_show.svg'} className='show-hide-icon' alt='eye' />
                  }
                </span>
              </div>
            </div>
            <div className='vertical-field-group'>
              <div className='label-input'>
                <label>new password*</label>
                <br />
                <input type={this.state.newPwdType} autoComplete='off' name='newPwd' id='newPwd' placeholder='Enter new password' value={this.state.newPwd} onChange={this.handleInputChange} onKeyPress={this.handleSubmitKeyPress} />
                <span className='pwdShow' onClick={() => this.handlePwdShowHide('newPwdType', this.state.newPwdType)}>
                  {this.state.newPwdType === 'text'
                    ? <img src={process.env.PUBLIC_URL + '/assets/images/icons/ic_hidden.svg'} className='show-hide-icon' alt='eye' />
                    : <img src={process.env.PUBLIC_URL + '/assets/images/icons/ic_show.svg'} className='show-hide-icon' alt='eye' />
                  }
                </span>
              </div>
            </div>
            <div className='vertical-field-group'>
              <div className='label-input'>
                <label>confirm password*</label>
                <br />
                <input type={this.state.confirmPwdType} autoComplete='off' name='confirmPwd' id='confirmPwd' placeholder='Re-enter password' value={this.state.confirmPwd} onChange={this.handleInputChange} onKeyPress={this.handleSubmitKeyPress} />
                <span className='pwdShow' onClick={() => this.handlePwdShowHide('confirmPwdType', this.state.confirmPwdType)}>
                  {this.state.confirmPwdType === 'text'
                    ? <img src={process.env.PUBLIC_URL + '/assets/images/icons/ic_hidden.svg'} className='show-hide-icon' alt='eye' />
                    : <img src={process.env.PUBLIC_URL + '/assets/images/icons/ic_show.svg'} className='show-hide-icon' alt='eye' />
                  }
                </span>
              </div>
            </div>
            <div className='bottom-section'>
              <div className='cancel-blk' onClick={this.props.hideOverlay}>
                <p className='cancel-btn'>Cancel</p>
              </div>
              <div className='submit-blk' onClick={this.handleSubmit}>
                <p className='submit-btn'>change password</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default ChangePassword
