import React, { Component } from 'react'
import { toast } from 'react-toastify'
import $ from 'jquery'
// component
import Toaster from '../../Toaster/Toaster'
import agent from '../../../api/Api'
// css
import './ResetPassword.css'

class ResetPassword extends Component {
  constructor (props) {
    super(props)
    this.state = {
      password: '',
      passwordType: 'password',
      confirmPassword: '',
      confirmPasswordType: 'password'
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleResetKeyPress = this.handleResetKeyPress.bind(this)
    this.handlePwdShowHide = this.handlePwdShowHide.bind(this)
  }

  handlePwdShowHide (name, type) {
    console.log(name)
    this.setState({ [name]: type === 'password' ? 'text' : 'password' })
  }

  handleInputChange (event) {
    const { name, value } = event.target
    this.setState({ [name]: value })
    if (value.length > 0) {
      $('#' + name).css({ border: '1px solid #ddd' })
    } else {
      $('#' + name).css({ border: '1px solid red' })
    }
  }

  handleSubmit () {
    $('#loader').fadeIn()
    let param = {
      password: this.state.password,
      confirmPassword: this.state.confirmPassword
    }

    let count = 0
    for (var key in param) {
      console.log(param, key)
      if (param[key]) {
        $('#' + key).css({ border: '1px solid #ddd' })
        count += 1
      } else {
        $('#' + key).css({ border: '1px solid red' })
      }
    }

    if (this.state.password && this.state.confirmPassword) {
      if (this.state.password !== this.state.confirmPassword) {
        toast.dismiss()
        toast(<Toaster title='Reset password' content='New password and Confirm password should be same' status='error' />)
        setTimeout(function () { toast.dismiss() }, 3000)
        $('#password').css({ border: '1px solid red' })
        $('#confirmPassword').css({ border: '1px solid red' })
      } else {
        $('#password').css({ border: '1px solid #ddd' })
        $('#confirmPassword').css({ border: '1px solid #ddd' })
      }
    }

    let url = ''
    if (this.props.role === 'teacher') url = '/teacher/resetPassword?password=' + this.state.password + '&encodedString=' + this.props.encodedString
    else url = '/admin/resetPassword?password=' + this.state.password + '&encodedString=' + this.props.encodedString

    // alert(count)
    if (count === 2) {
      delete param.confirmPassword
      param.encodedString = this.props.encodedString
      agent.APILIST.resetPassword(url)
        .then(res => {
          if (res.status === 'SUCCESS') {

            $('#loader').fadeOut()
            this.props.handleRedirectToSignin('SUCCESS', this.props.role)
            toast.dismiss()
            toast(<Toaster title='Teacher Login' content={res.message} status='error' />)
            setTimeout(function () { toast.dismiss() }, 3000)
          }
        })
        .catch(err => {
          $('#loader').fadeOut()
          console.log(err.response.text)
          let res = JSON.parse(err.response.text)
          toast.dismiss()
          toast(<Toaster title='Teacher Login' content={res.message} status='error' />)
          setTimeout(function () { toast.dismiss() }, 3000)
        })
    } else {
      window.scrollTo(0, 0)
      $('#loader').fadeOut()
    }
  }

  handleResetKeyPress (event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleSubmit()
    }
  }

  render () {
    return (
      <div id='resetpassword-form'>
        <div className='resetpassword-outer-blk noselect'>
          <div className='resetpassword-blk'>
            <div className='title-blk'>
              <p className='resetpassword-title'>Reset Password</p>
              <p className='resetpassword-desc'>Sign In here to manage masks and educators</p>
            </div>
            <div className='vertical-field-group'>
              <div className='label-input'>
                <label>new password*</label>
                <br />
                <input type={this.state.passwordType} autoComplete='off' name='password' id='password' placeholder='Enter your password' value={this.state.password} onChange={this.handleInputChange} onKeyPress={this.handleResetKeyPress} />
                <span className='pwdShow' onClick={() => this.handlePwdShowHide('passwordType', this.state.passwordType)}>
                  {this.state.passwordType === 'text'
                    ? <img src={process.env.PUBLIC_URL + '/assets/images/icons/ic_hidden.svg'} className='show-hide-icon' alt='eye' />
                    : <img src={process.env.PUBLIC_URL + '/assets/images/icons/ic_show.svg'} className='show-hide-icon' alt='eye' />
                  }
                </span>
              </div>
            </div>
            <div className='vertical-field-group'>
              <div className='label-input'>
                <label>confirm password*</label>
                <br />
                <input type={this.state.passwordType} autoComplete='off' name='confirmPassword' id='confirmPassword' placeholder='Enter your password' value={this.state.confirmPassword} onChange={this.handleInputChange} onKeyPress={this.handleResetKeyPress} />
                <span className='pwdShow' onClick={() => this.handlePwdShowHide('confirmPasswordType', this.state.confirmPasswordType)}>
                  {this.state.passwordType === 'text'
                    ? <img src={process.env.PUBLIC_URL + '/assets/images/icons/ic_hidden.svg'} className='show-hide-icon' alt='eye' />
                    : <img src={process.env.PUBLIC_URL + '/assets/images/icons/ic_show.svg'} className='show-hide-icon' alt='eye' />
                  }
                </span>
              </div>
            </div>
            <div className='submit-section'>
              <div className='submit-blk' onClick={this.handleSubmit}>
                <p className='submit-btn'>submit</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default ResetPassword
