import React, { Component } from 'react'
import { toast } from 'react-toastify'
import PhoneInput from 'react-phone-input-2'
import $ from 'jquery'
// component
import Toaster from '../Toaster/Toaster'
import agent from '../../api/Api'
// css
import './EditProfile.css'

class EditProfile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      name: '',
      phoneNumber: '',
      city: '',
      state: '',
      address: '',
      zip: '',
      schoolName: '',
      schoolType: '',
      schoolGrade: '',
      userInfo: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleInputPhoneChange = this.handleInputPhoneChange.bind(this)
  }

  handleInputPhoneChange (event) {
    this.setState({ phoneNumber: event })
  }

  handleInputChange (event) {
    const { name, value } = event.target
    this.setState({ [name]: value })
    if (value.length > 0) {
      $('#' + name).css({border: '1px solid #ddd'})
    } else {
      $('#' + name).css({border: '1px solid red'})
    }
  }

  handleSubmit () {
    $('#loader').fadeIn()
    let param = {
      name: this.state.name,
      phoneNumber: this.state.phoneNumber,
      city: this.state.city,
      state: this.state.state,
      address: this.state.address,
      zip: this.state.zip,
      schoolName: this.state.schoolName,
      schoolType: this.state.schoolType,
      schoolGrade: this.state.schoolGrade
    }

    let count = 0
    for (var key in param) {
      if (param[key]) {
        $('#' + key).css({ border: '1px solid #ddd' })
        count += 1
      } else {
        $('#' + key).css({ border: '1px solid red' })
      }
    }
    console.log(count)

    // alert(count)
    param.email = this.state.email
    agent.APILIST.teacherSignin('/teacher/editProfile', param)
      .then(res => {
        if (res.status === 'SUCCESS') {
          // update local info
          var loginCheck = JSON.parse(localStorage.getItem('userInfo'))
          loginCheck.name = this.state.name
          loginCheck.phoneNumber = this.state.phoneNumber
          loginCheck.city = this.state.city
          loginCheck.state = this.state.state
          loginCheck.address = this.state.address
          loginCheck.zip = this.state.zip
          loginCheck.schoolName = this.state.schoolName
          loginCheck.schoolType = this.state.schoolType
          loginCheck.schoolGrade = this.state.schoolGrade
          localStorage.setItem('userInfo', JSON.stringify(loginCheck))

          $('#loader').fadeOut()
          this.props.hideOverlay()
          toast.dismiss()
          toast(<Toaster title='Profile' content={res.message} status='success' />)
          setTimeout(function () { toast.dismiss() }, 3000)
        }
      })
      .catch(err => {
        let res = JSON.parse(err.response.text)
        toast.dismiss()
        toast(<Toaster title='Profile' content={res.message} status='error' />)
        setTimeout(function () { toast.dismiss() }, 3000)
        $('#loader').fadeOut()
      })
  }

  componentDidMount () {
    var loginCheck = JSON.parse(localStorage.getItem('userInfo'))
    // console.log('loginCheck', loginCheck)
    if (loginCheck) {
      this.setState({
        userInfo: loginCheck,
        email: loginCheck.email,
        name: loginCheck.name,
        phoneNumber: loginCheck.phoneNumber,
        city: loginCheck.city,
        state: loginCheck.state,
        address: loginCheck.address,
        zip: loginCheck.zip,
        schoolName: loginCheck.schoolName,
        schoolType: loginCheck.schoolType,
        schoolGrade: loginCheck.schoolGrade
      })
    }
  }

  render () {
    return (
      <div id='editprofile-form'>
        <div className='editprofile-outer-blk noselect'>
          <div className='editprofile-blk'>
            <div className='title-blk'>
              <p className='editprofile-title'>Edit profile</p>
            </div>
            {/* row 1 */}
            <div className='horizontal-field-group'>
              <div className='label-input'>
                <label>name*</label>
                <br />
                <input type='text' autoFocus name='name' id='name' maxLength='25' placeholder='Enter your name' value={this.state.name} onChange={this.handleInputChange} />
              </div>
              <div className='label-input'>
                <label>phone*</label>
                <br />
                <PhoneInput
                  title={this.state.phoneNumber}
                  country='us'
                  id='phoneNumber'
                  name='phoneNumber'
                  ref='phone' value={this.state.phoneNumber} placeholder='Enter your phone number'
                  autoComplete='off' onChange={this.handleInputPhoneChange}
                />
              </div>
            </div>
            {/* row 5 */}
            <div className='horizontal-field-group'>
              <div className='label-input'>
                <label>school/company name*</label>
                <br />
                <input type='text' name='schoolName' id='schoolName' maxLength='50' placeholder='Enter your school name' value={this.state.schoolName} onChange={this.handleInputChange} />
              </div>
              <div className='label-input'>
                <label>school type*</label>
                <br />
                <select name='schoolType' id='schoolType' value={this.state.schoolType} onChange={this.handleInputChange}>
                  <option value=''>Select one...</option>
                  <option value='Public/District'>Public/District</option>
                  <option value='Independent'>Independent</option>
                  <option value='Charter'>Charter</option>
                  <option value='Other'>Other</option>
                </select>
              </div>
            </div>

            {/* row 6 */}
            <div className='horizontal-field-group'>
              <div className='label-input'>
                <label>school grade level*</label>
                <br />
                <select name='schoolGrade' id='schoolGrade' value={this.state.schoolGrade} onChange={this.handleInputChange}>
                  <option value=''>Select one...</option>
                  <option value='Elementary School'>Elementary School</option>
                  <option value='Middle School'>Middle School</option>
                  <option value='High School'>High School</option>
                  <option value='Other'>Other</option>
                </select>
              </div>
              <div className='label-input'>
                <label>state*</label>
                <br />
                <input type='text' name='state' id='state' maxLength='25' placeholder='e.g. San Francisco' value={this.state.state} onChange={this.handleInputChange} />
              </div>
            </div>

            {/* row 7 */}
            <div className='horizontal-field-group'>
              <div className='label-input'>
                <label>city*</label>
                <br />
                <input type='text' name='city' id='city' maxLength='25' placeholder='e.g. San Francisco' value={this.state.city} onChange={this.handleInputChange} />
              </div>
              <div className='label-input'>
                <label>ZIP/postal code*</label>
                <br />
                <input type='text' name='zip' id='zip' maxLength='10' placeholder='e.g. 94105' value={this.state.zip} onChange={this.handleInputChange} />
              </div>
            </div>

            {/* row 8 */}
            <div className='horizontal-field-group'>
              <div className='label-input'>
                <label>Address*</label>
                <br />
                <input type='text' name='address' id='address' maxLength='50' placeholder='e.g. 12/7 Down street' value={this.state.address} onChange={this.handleInputChange} />
              </div>
            </div>

            <div className='bottom-section'>
              <div className='cancel-blk' onClick={this.props.hideOverlay}>
                <p className='cancel-btn'>Cancel</p>
              </div>
              <div className='submit-blk' onClick={this.handleSubmit}>
                <p className='submit-btn'>save changes</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default EditProfile
