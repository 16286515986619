import React, { Component } from 'react'
import { toast } from 'react-toastify'
import $ from 'jquery'
// component
import Toaster from '../../Toaster/Toaster'
import agent from '../../../api/Api'
// css
import './SignInForm.css'

class SignInForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      passwordType: 'password'
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSignInKeyPress = this.handleSignInKeyPress.bind(this)
    this.handlePwdShowHide = this.handlePwdShowHide.bind(this)
  }

  handlePwdShowHide (name, type) {
    console.log(name)
    this.setState({ [name]: type === 'password' ? 'text' : 'password' })
  }

  handleInputChange (event) {
    const { name, value } = event.target
    this.setState({ [name]: value })
    if (value.length > 0) {
      $('#' + name).css({border: '1px solid #ddd'})
    } else {
      $('#' + name).css({border: '1px solid red'})
    }
  }

  handleSubmit () {
    $('#loader').fadeIn()
    let param = {
      email: this.state.email,
      password: this.state.password
    }

    let count = 0
    for (var key in param) {
      if (param[key]) {
        $('#' + key).css({ border: '1px solid #ddd' })
        count += 1
      } else {
        $('#' + key).css({ border: '1px solid red' })
      }
    }
    let url = ''
    if (this.props.role === 'teacher') url = '/teacher/login'
    else url = '/admin/login'
    // alert(count)
    if (count === 2) {
      agent.APILIST.teacherSignin(url, param)
        .then(res => {
          if (res.status === 'SUCCESS') {
            $('#loader').fadeOut()
            if (this.props.role === 'admin') {
              localStorage.setItem('userInfo', JSON.stringify(res.adminResponseData))
            } else {
              localStorage.setItem('userInfo', JSON.stringify(res.data))
            }
            this.props.handleSignIn('SUCCESS', this.props.role)
          }
        })
        .catch(err => {
          $('#loader').fadeOut()
          if (err.response.text) {
            console.log(err.response.text)
            let res = JSON.parse(err.response.text)
            toast.dismiss()
            toast(<Toaster title='Teacher Login' content={res.message} status='error' />)
            setTimeout(function () { toast.dismiss() }, 3000)
          }
        })
    } else {
      window.scrollTo(0, 0)
      $('#loader').fadeOut()
    }
  }

  handleSignInKeyPress (event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleSubmit()
    }
  }

  render () {
    return (
      <div id='signin-form'>
        <div className='signin-outer-blk noselect'>
          {this.props.role !== 'admin'
            ? <a className='back-blk' href='/'>
              <img className='arrow-black-left' src={process.env.PUBLIC_URL + 'assets/images/icons/arrow-black-left.svg'} alt='arrow' />
              <p>back</p>
            </a>
            : ''
          }
          <div className='signin-blk'>
            <div className='title-blk'>
              <p className='signin-title'>sign in</p>
              <p className='signin-desc'>{this.props.role === 'admin' ? 'Sign In here to manage masks and educators' : 'Welcome, We are happy to see you back!'}</p>
            </div>
            <div className='vertical-field-group'>
              <div className='label-input'>
                <label>email*</label>
                <br />
                <input type='text' autoFocus autoComplete='off' name='email' id='email' placeholder='Enter your email address' value={this.state.email} onChange={this.handleInputChange} onKeyDown={this.handleSignInKeyPress} />
              </div>
            </div>
            <div className='vertical-field-group'>
              <div className='label-input'>
                <label>password*</label>
                <br />
                <input type={this.state.passwordType} autoComplete='off' name='password' id='password' placeholder='Enter your password' value={this.state.password} onChange={this.handleInputChange} onKeyDown={this.handleSignInKeyPress} />
                <span className='pwdShow' onClick={() => this.handlePwdShowHide('passwordType', this.state.passwordType)}>
                  {this.state.passwordType === 'text'
                    ? <img src={process.env.PUBLIC_URL + '/assets/images/icons/ic_hidden.svg'} className='show-hide-icon' alt='eye' />
                    : <img src={process.env.PUBLIC_URL + '/assets/images/icons/ic_show.svg'} className='show-hide-icon' alt='eye' />
                  }
                </span>
              </div>
            </div>
            {this.props.role === 'admin'
              ? <div className='forgotPwd'><a href='/auth/admin/forgotpassword'>Forgot Password?</a></div>
              : <div className='forgotPwd'><a href='/auth/teacher/forgotpassword'>Forgot Password?</a></div>}
            <div className='submit-section'>
              <div className='submit-blk' onClick={this.handleSubmit}>
                <p className='submit-btn'>sign in</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default SignInForm
