import React, { Component } from 'react'
import {motion} from 'framer-motion'
import { Navigate } from 'react-router-dom'
import $ from 'jquery'
// component
import agent from '../../api/Api'
// import Header from './GalleryHeader/GalleryHeader'
import Header from '../Header/Header'
import { debounce } from 'throttle-debounce'
import Overlay from '../Overlay/Overlay'
// css
import './Gallery.css'

class Gallery extends Component {
  constructor (props) {
    super(props)
    this.state = {
      role: '',
      redirect: false,
      redirectTarget: '',
      userInfo: { teacherCode: '', additionalCodes: [] },
      loggedIn: false,
      typeOfComponent: '',
      maskPreviewIndex: '',
      age: '',
      teacherCode: '',
      word: '',
      location: '',
      selectedOptions: [],
      selectedFilterValues: [],
      maskData: [],
      nextCursor: null,
      isNextPageAvailable: false,
      nextPage: false
    }
    this.handleGetMask = debounce(500, this.handleGetMask.bind(this))
    this.handleFilterInputChange = this.handleFilterInputChange.bind(this)
    this.handleFilterSelectChange = this.handleFilterSelectChange.bind(this)
    this.handleFilterClear = this.handleFilterClear.bind(this)
    this.handleRemoveSpecificFilter = this.handleRemoveSpecificFilter.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.handleMaskPreview = this.handleMaskPreview.bind(this)
    this.hideOverlay = this.hideOverlay.bind(this)
    this.handleNextPrev = this.handleNextPrev.bind(this)
  }

  componentDidMount () {
    var loginCheck = JSON.parse(localStorage.getItem('userInfo'))
    if (loginCheck) {
      this.setState({ userInfo: loginCheck, role: loginCheck.role, loggedIn: true })
    } else {
      if (this.props?.match?.url === '/teacher/dashboard' || this.props?.match?.url === '/admin/dashboard') this.setState({ redirect: true, redirectTarget: '/gallery' })
      else this.setState({ role: 'gallery' })
    }
    $('#loader').fadeIn()
    // get data from remote
    this.handleGetMask('')
  }

  handle1GetAllMask (emailId) {
    let param = '/mask/getMasksByTeacherId?email=' + emailId
    if (this.state.nextPage) {
      $('#loader').fadeIn()
      param += 'cursor=' + this.state.nextCursor
    }

    agent.APILIST.getMask(param)
      .then(res => {
        if (res.status === 'SUCCESS') {
          if (this.state.nextPage) {
            const data = this.state.maskData
            const newData = [...data, ...res.data.maskDetails]
            this.setState({ maskData: newData }, () => {
            })
          } else {
            this.setState({ maskData: res.data.maskDetails })
          }
          this.setState({ nextCursor: res.data.nextCursor, isNextPageAvailable: res.data.isNextPageAvailable, nextPage: false })
          $('#loader').fadeOut()
        }
      })
      .catch(err => {
        $('#loader').fadeOut()
        console.log(err)
      })
  }

  handleGetMask (obj) {
    const { teacherCode } = this.props?.match?.params || {teacherCode:''}
    this.setState({ teacherCode })
    var getMaskUrl = ''
    if (this.props?.match?.url === '/teacher/dashboard') getMaskUrl = '/mask/getMasksByTeacherId?email=' + this.state.userInfo.email // TODO update url with teacher code
    else getMaskUrl = '/mask/gallery' // TODO update url with teacher code

    let param = ''

    if (this.state.nextPage) {
      $('#loader').fadeIn()
      param += 'cursor=' + this.state.nextCursor + '&&'
    }

    for (let j = 0; j < obj.length; j++) {
      param += obj[j].name + '=' + (obj[j].name === 'location' || obj[j].name === 'word' ? obj[j].value.toLowerCase() : obj[j].value)
      if (j < obj.length - 1) {
        param += '&&'
      }
    }
    console.log('param', param)
    if (param) {
      if (this.props?.match?.url === '/teacher/dashboard') param = '&&' + param
      else param = '?' + param
    }

    agent.APILIST.getMask(getMaskUrl + param)
      .then(res => {
        if (res.status === 'SUCCESS') {
          if (this.state.nextPage) {
            const data = this.state.maskData
            const newData = [...data, ...res.data.maskDetails]
            this.setState({ maskData: newData }, () => {
            })
          } else {
            this.setState({ maskData: res.data.maskDetails })
          }
          this.setState({ nextCursor: res.data.nextCursor, isNextPageAvailable: res.data.isNextPageAvailable, nextPage: false })
          $('#loader').fadeOut()
        }
      })
      .catch(err => {
        console.log(err)
        $('#loader').fadeOut()
      })
  }

  handleFilterInputChange (event) {
    if (this.state.showDownload) {
      $('.checkMask').prop('checked', false)
      this.setState({ showDownload: false, checkedMask: [] })
    }
    const { name, value } = event.target
    // console.log(name, value)
    const obj = this.state.selectedFilterValues
    const options = this.state.selectedOptions
    let verify = false
    if (name === 'age') {
      if (value.length <= 2 && value[0] !== '0') {
        const re = /^[0-9]+$/
        if (re.test(value) || value === '') {
          this.setState({ [name]: value })
          verify = true
        } else {
          verify = false
        }
      }
    } else if (name === 'location') {
      const re = /^[0-9A-Za-z ]+$/
      if (re.test(value) || value === '') {
        this.setState({ [name]: value })
        verify = true
      } else {
        verify = false
      }
    } else if (name === 'word') {
      const re = /^[0-9A-Za-z ]+$/
      if (re.test(value) || value === '') {
        this.setState({ [name]: value })
        verify = true
      } else {
        verify = false
      }
    }

    // console.log('ver', verify)
    if (verify) {
      let done = false
      for (let k = 0; k < obj.length; k++) {
        if (obj[k].name === name) {
          obj[k].value = value
          done = true
        }
      }

      if (!done) {
        obj.push({ name: name, value: value })
        options.push(name)
      }
      if (value.length === 0) {
        options.splice(options.indexOf(name), 1)
        obj.splice(options.indexOf(name), 1)
      }
      this.setState({ selectedFilterValues: obj, selectedOptions: options })
      this.handleGetMask(obj)
    }
  }

  handleFilterSelectChange (event) {
    if (this.state.showDownload) {
      $('.checkMask').prop('checked', false)
      this.setState({ showDownload: false, checkedMask: [] })
    }
    const { name, value } = event.target
    const options = this.state.selectedOptions
    const obj = this.state.selectedFilterValues

    if (value) {
      if (options.indexOf(name) >= 0) {
        // add selected filter from array
        obj[options.indexOf(name)].value = value
        this.setState({ selectedFilterValues: obj })
        this.handleGetMask(obj)
      } else {
        // add selected option and selected filter to array
        options.push(name)
        obj.push({ name: name, value: value })
        this.setState({ selectedFilterValues: obj, selectedOptions: options })
        this.handleGetMask(obj)
      }
    } else {
      const removeOption = this.state.selectedOptions.indexOf(name)
      // remove selected filter from array
      const obj = this.state.selectedFilterValues
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].name === name) {
          obj.splice(i, 1)
        }
      }
      // remove selected option from array
      for (let j = 0; j < options.length; j++) {
        options.splice(removeOption, 1)
      }
      this.setState({ selectedFilterValues: obj, selectedOptions: options })
      this.handleGetMask(obj)
    }
  }

  handleCheckboxChange (event) {
    const { checked } = event.target
    const temp = this.state.checkedMask
    // console.log(event.target.dataset.id, event.target.dataset.index, checked)
    const index = event.target.dataset.index

    if (checked) {
      temp.push(this.state.maskData[index])
      this.setState({ checkedMask: temp })
    } else {
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].maskId === parseInt(event.target.dataset.id)) {
          temp.splice(i, 1)
          this.setState({ checkedMask: temp })
        }
      }
    }

    if (temp.length > 0) {
      this.setState({ showDownload: true })
    } else {
      this.setState({ showDownload: false })
    }
    // console.log('temp', temp)
  }

  handleFilterClear () {
    if (this.state.showDownload) {
      $('.checkMask').prop('checked', false)
      this.setState({ showDownload: false, checkedMask: [] })
    }

    this.setState({
      selectedFilterValues: [],
      selectedOptions: [],
      word: '',
      age: '',
      location: ''
    })
    const selectTags = document.getElementsByTagName('select')
    for (var i = 0; i < selectTags.length; i++) {
      selectTags[i].selectedIndex = 0
    }
    this.handleGetMask('')
  }

  handleRemoveSpecificFilter (name) {
    if (this.state.showDownload) {
      $('.checkMask').prop('checked', false)
      this.setState({ showDownload: false, checkedMask: [] })
    }

    const options = this.state.selectedOptions
    const obj = this.state.selectedFilterValues

    // remove selected filter from array
    for (let i = 0; i < obj.length; i++) {
      if (obj[i].name === name) {
        obj.splice(i, 1)
      }
    }

    $('#' + name).val('')
    this.setState({ [name]: '' })

    // remove selected option from array
    const removeOption = options.indexOf(name)
    options.splice(removeOption, 1)

    this.setState({ selectedFilterValues: obj, selectedOptions: options })
    this.handleGetMask(obj)
  }

  handleNextPrev (flag) {
    const obj = this.state.selectedFilterValues
    if (flag === 'N' && this.state.isNextPageAvailable) {
      this.setState({ nextPage: true }, () => {
        this.handleGetMask(obj)
      })
    }
  }

  handleMaskPreview (index) {
    this.setState({ typeOfComponent: 'MaskPreview', maskPreviewIndex: index })
  }

  hideOverlay () {
    this.setState({ typeOfComponent: '' })
  }

  render () {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirectTarget} replace />
    }
    const componentOverlay = this.state.typeOfComponent === 'MaskPreview'
      ? <Overlay typeOfComponent='MaskPreview' size='img' hideOverlay={this.hideOverlay.bind(this)} maskData={this.state.maskData} maskPreviewIndex={this.state.maskPreviewIndex} />
      : ''
    return (
      <div id='gallery'>
        {componentOverlay}
        <Header loggedIn={this.state.loggedIn} role={this.state.role} />
        <div className='gallery-outer-blk noselect'>
          <div className='gallery-inner-blk'>
            <div className='gallery-top-blk'>
              <div className='gallery-top-row'>
                <div className='title-blk'>
                  <div>GALLERY</div>
                  {this.state.role === 'teacher'
                    ? <a className='title-link' href='/teacher/dashboard'>Go to Educator View</a>
                    : ''}
                </div>
                <div className='filter-outer-blk'>
                  <div className='filter-inner-blk'>
                    <div className={'filter-blk ' + this.state.role}>
                      <label className='filter-title'>filter by</label>
                      <div className='hr-line' />
                      <input type='text' onChange={this.handleFilterInputChange} value={this.state.age} name='age' placeholder='Age' id='age' />
                      <select onChange={this.handleFilterSelectChange} name='gender' id='gender'>
                        <option value=''>Gender</option>
                        <option value='Male'>Male</option>
                        <option value='Female'>Female</option>
                        <option value='Other'>Other</option>
                        <option value='Prefer not to answer'>Prefer not to answer</option>
                      </select>
                      <input type='text' maxLength='25' onChange={this.handleFilterInputChange} value={this.state.location} name='location' placeholder='City' id='location' />
                    </div>
                    <div className='search-blk'>
                      <img src={process.env.PUBLIC_URL + '/assets/images/icons/Search-small.png'} alt='Search-Icon' />
                      <input type='text' placeholder={this.state.role === 'gallery' ? 'Search with Teacher’s code or any keywords' : 'Search with any keywords'} name='word' maxLength='25' onChange={this.handleFilterInputChange} value={this.state.word} />
                    </div>
                  </div>
                </div>
                <div className='clr' />
              </div>

              <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:0.3}} >
                <div className={this.state.selectedOptions.length ? 'gallery-bottom-row' : 'gallery-bottom-row hide'}>
                  <p className='result-found'>{this.state.maskData.length} search results for</p>
                  <div className='selected-filter-blk'>
                    {this.state.selectedFilterValues.map((list, index) => {
                      return (
                        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:0.3}} key={index}>
                          <div className='selected-filter-option'>
                            <span className='label'>{list.name}</span>
                            <span>{list.value}</span>
                            <img className='close-img' src={process.env.PUBLIC_URL + '/assets/images/icons/Close.png'} alt='close' onClick={() => this.handleRemoveSpecificFilter(list.name)} />
                          </div>
                        </motion.div>
                      )
                    })}
                  </div>
                  <div className='clear-filter' onClick={this.handleFilterClear}>Clear All</div>
                </div>
              </motion.div>
            </div>
            <div className='gallery-content-outer'>
              <div className='gallery-content-blk'>
                {this.state.maskData.map((list, index) => {
                  return (<div className='mask-blk' key={index}>
                    <div className='mask-top'>
                      <img src={'https://storage.googleapis.com/ever-forward-club.appspot.com/images/' + list.maskGuid + '.png'} alt='mask' onClick={() => this.handleMaskPreview(index)} />
                    </div>
                    <div className='mask-bottom'>
                      <div className='mask-info'><span>{list.age} Yrs</span> | <span title={list.gender.length > 15 ? list.gender : ''}>{list.gender.length > 15 ? list.gender.substring(0, 15) + '...' : list.gender}</span> | <span style={{ textTransform: 'capitalize' }} title={list.city.length > 15 ? list.city : ''}>{list.city.length > 15 ? list.city.substring(0, 15) + '...' : list.city}</span></div>
                    </div>
                  </div>)
                })}
                {this.state.maskData.length === 0 ? <div><h1 style={{ textAlign: 'center', color: '#FFF' }}>No Mask Found</h1></div> : ''}
                <div className='pagination-outer'>
                  {this.state.isNextPageAvailable
                    ? (<div className='pagination-inner'>
                      <div className='next-blk' onClick={() => this.handleNextPrev('N')}><p>Show more</p></div>
                    </div>)
                    : ''}
                </div>
              </div>
            </div>
            <div className='footer'>
              <p>© everforwardclub.org - 2020</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Gallery
