import React, { Component } from 'react'
import { toast } from 'react-toastify'
import {motion} from 'framer-motion'
import $ from 'jquery'
// component
import Toaster from '../Toaster/Toaster'
import agent from '../../api/Api'
// css
import './Help.css'

class Help extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      gender: '',
      genderOthers: '',
      age: '',
      message: '',
      userInfo: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange (event) {
    const { name, value } = event.target
    if (name === 'age') {
      let re = /^[0-9\b]+$/
      if (re.test(value) || value === '') this.setState({ [name]: value })
    } else {
      this.setState({ [name]: value })
    }
    if (value.length > 0) {
      $('#' + name).css({ border: '1px solid #ddd' })
    } else {
      $('#' + name).css({ border: '1px solid red' })
    }
  }

  handleSubmit () {
    $('#loader').fadeIn()
    let param = {
      email: this.state.email,
      gender: this.state.gender,
      age: this.state.age,
      message: this.state.message
    }

    let count = 0
    for (var key in param) {
      if (param[key]) {
        $('#' + key).css({ border: '1px solid #ddd' })
        count += 1
      } else {
        $('#' + key).css({ border: '1px solid red' })
      }
    }

    if (param.gender === 'Other') {
      if (this.state.genderOthers) {
        param.gender = this.state.genderOthers
        $('#genderOthers').css({ border: '1px solid #ddd' })
      } else {
        count = 0
        $('#genderOthers').css({ border: '1px solid red' })
      }
    }

    console.log(count)
    if (count === 4) {
      agent.APILIST.teacherSignin('/teacher/submitHelp', param)
        .then(res => {
          if (res.status === 'SUCCESS') {
            $('#loader').fadeOut()
            this.props.hideOverlay()
            toast.dismiss()
            toast(<Toaster title='Help' content={res.message} status='success' />)
            setTimeout(function () { toast.dismiss() }, 3000)
          }
        })
        .catch(err => {
          let res = JSON.parse(err.response.text)
          toast.dismiss()
          toast(<Toaster title='Help' content={res.message} status='error' />)
          setTimeout(function () { toast.dismiss() }, 3000)
          $('#loader').fadeOut()
        })
    } else {
      window.scrollTo(0, 0)
      $('#loader').fadeOut()
    }
  }

  componentDidMount () {
    var loginCheck = JSON.parse(localStorage.getItem('userInfo'))
    // console.log('loginCheck', loginCheck)
    if (loginCheck) {
      this.setState({ userInfo: loginCheck })
    }
  }

  render () {
    return (
      <div id='help-form'>
        <div className='help-outer-blk noselect'>
          <div className='help-blk'>
            <div className='title-blk'>
              <p className='help-title'>We are here to help!</p>
            </div>
            <div className='vertical-field-group'>
              <div className='label-input'>
                <label>email*</label>
                <br />
                <input type='text' autoFocus name='email' id='email' maxLength='25' placeholder='Enter your email' value={this.state.email} onChange={this.handleInputChange} />
              </div>
            </div>
            <div className='vertical-field-group'>
              <div className='label-input'>
                <label>gender*</label>
                <br />
                <select name='gender' id='gender' value={this.state.gender} onChange={this.handleInputChange}>
                  <option value=''>Select one...</option>
                  <option value='Male'>Male</option>
                  <option value='Female'>Female</option>
                  <option value='Other'>Other</option>                  
                  <option value='Prefer not to answer'>Prefer not to answer</option>
                </select>
              </div>
            </div>
            {this.state.gender === 'Other'
              ? (<motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:0.3}} >
                <div className='vertical-field-group'>
                  <div className='label-input'>
                    <input type='text' name='genderOthers' id='genderOthers' maxLength='25' placeholder='Enter your gender' value={this.state.genderOthers} onChange={this.handleInputChange} />
                  </div>
                </div>
              </motion.div>)
              : ''
            }
            <div className='vertical-field-group'>
              <div className='label-input'>
                <label>age*</label>
                <br />
                <input type='text' name='age' id='age' maxLength='25' placeholder='Enter your age' value={this.state.age} onChange={this.handleInputChange} />
              </div>
            </div>
            <div className='vertical-field-group'>
              <div className='label-input'>
                <label>message*</label>
                <br />
                <textarea type='text' name='message' id='message' maxLength='255' rows='4' placeholder='Enter your message' value={this.state.message} onChange={this.handleInputChange} />
              </div>
            </div>
            <div className='bottom-section'>
              <div className='cancel-blk' onClick={this.props.hideOverlay}>
                <p className='cancel-btn'>Cancel</p>
              </div>
              <div className='submit-blk' onClick={this.handleSubmit}>
                <p className='submit-btn'>submit</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default Help
