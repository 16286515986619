import React, { Component} from 'react'
import $ from 'jquery'
import { toast } from 'react-toastify'
import Toaster from '../Toaster/Toaster'
// component
import agent from '../../api/Api'
// css
import './DrawPreview.css'

class DrawPreview extends Component {
  constructor (props) {
    super(props)
    this.state = {
      typeOfComponent: '',
      age: '',
      gender: '',
      genderOthers: '',
      city: '',
      teacherCode: '',
      teacherCodeList: []
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.HandleGetTeacherCode = this.HandleGetTeacherCode.bind(this)
    this.handleStudentSubmit = this.handleStudentSubmit.bind(this)
  }
   
  componentDidMount () {
    console.log('props', this.props)
    if (this.props.param.teacherCode) {
      this.setState({teacherCode: this.props.param.teacherCode})
    }
    this.HandleGetTeacherCode()
  }

  HandleGetTeacherCode () {
    agent.APILIST.getTeacherCode('/teacher/code/list')
      .then(res => {
        if (res.status === 'SUCCESS') {
          this.setState({teacherCodeList: res.data})
        }
      })
      .catch(err => {
        console.log(err)
        $('#loader').fadeOut()
      })
  }

  handleInputChange (event) {
    const {name, value} = event.target
    if (name === 'age') {
      if (value.length <= 2) {
        if (value[0] !== '0') {
          let re = /^[0-9]+$/
          if (re.test(value) || value === '') this.setState({[name]: value})
        }
      }
    } else {
      let re = /^[0-9A-Za-z ]+$/
      if (re.test(value) || value === '') this.setState({[name]: value})
    }
    if (value.length > 0) {
      $('#' + name).css({border: '1px solid #ddd'})
    } else {
      $('#' + name).css({border: '1px solid red'})
    }
  }

  handleStudentSubmit (flag) {
    let param = this.props.param
    let count = 0
    param.age = this.state.age
    param.gender = this.state.gender
    param.city = this.state.city.toLowerCase().trim()
    param.teacherCode = this.state.teacherCode

    let mandatory = ['age', 'gender', 'city']
    // let mandatory = []
    for (var key in param) {
      if (mandatory.indexOf(key) !== -1) {
        if (param[key]) {
          $('#' + key).css({border: '1px solid #ddd'})
          count += 1
        } else {
          $('#' + key).css({border: '1px solid red'})
        }
      }
    }

    if (param.gender === 'Other') {
      if (this.state.genderOthers) {
        param.gender = this.state.genderOthers
        $('#genderOthers').css({border: '1px solid #ddd'})
      } else {
        count = 0
        $('#genderOthers').css({border: '1px solid red'})
      }
    }

    if (param.teacherCode.trim()) {
      if (this.state.teacherCodeList.indexOf(param.teacherCode) !== -1) {
        $('#teacherCode').css({border: '1px solid #ddd'})
      } else {
        count = 0
        $('#teacherCode').css({border: '1px solid red'})
        toast.dismiss()
        toast(<Toaster title='Teacher Login' content="Invalid teacher code" status='error' />)
        setTimeout(function () { toast.dismiss() }, 3000)
      }
    }

    if (flag === 'SUBMIT') {
      if (count === 3) {
        delete param.originalMask
        this.props.hideOverlay('SUBSCRIBE', param)
      }
    } else {
      this.props.hideOverlay('BOARD', param)
    }
  }

  render () {
    return (
      <div id='draw-preview'>
        <div className='draw-preview-outer noselect'>
          <div className='mask-outer-blk'>
            <div className='mask-blk'>
              <div className='mask-top'>
                <img className='mask-img' src={this.props.param.imageData} alt='mask' />
              </div>
              {/* <div className='mask-bottom'>
                <a className='download-btn' href={this.props.param.imageData} download>
                  <img className='download-img' src={process.env.PUBLIC_URL + '/assets/images/icons/download-solid.png'} alt='download' />
                  <span className='download-label'>Download</span>
                </a>
              </div> */}
            </div>
          </div>
          <div className='metadata-blk'>
            <div className='metadata-form-blk'>
              <p className='title'>Please fill these to submit your mask.</p>
              <div className='side-by-side'>
                <div className='label-input'>
                  <label>Gender*</label>
                  <select name='gender' id='gender' onChange={this.handleInputChange}>
                    <option value=''>Select</option>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                    <option value='Transgender'>Transgender</option>
                    <option value='Non-Binary, Genderfluid, Agender, Culturally Specific Gender'>Non-Binary, Genderfluid, Agender, Culturally Specific Gender</option>
                    <option value='Questioning'>Questioning</option>
                    <option value='Prefer not to answer'>Prefer not to answer</option>
                  </select>
                </div>
                <div className='label-input'>
                  <label>Age*</label>
                  <input type='text' name='age' id='age' placeholder='Age' value={this.state.age} onChange={this.handleInputChange} />
                </div>
              </div>
              { this.state.gender === 'Other'
                ? (<div className='side-by-side'>
                  <div className='label-input'>
                    <input type='text' name='genderOthers' id='genderOthers' maxLength='25' placeholder='Enter your gender' value={this.state.genderOthers} onChange={this.handleInputChange} />
                  </div>
                </div>)
                : ''
              }
              <div className='label-input'>
                <label>city, State, Region, or Country*</label>
                <input type='text' name='city' id='city' maxLength='25' placeholder='City, State, Region, or Country' value={this.state.city} onChange={this.handleInputChange} />
              </div>
              <div className='label-input'>
                <label>teacher code (optional)</label>
                {/* <label>teacher code</label> */}
                <input type='text' id='teacherCode' name='teacherCode' value={this.state.teacherCode} onChange={this.handleInputChange} />
              </div>
              <div className='metadata-btn-blk'>
                <div className='submit-btn' onClick={() => this.handleStudentSubmit('SUBMIT')}>submit mask</div>
                <div className='cancel-btn' id='cancel-submit-btn' onClick={() => this.handleStudentSubmit('CANCEL')}>cancel</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DrawPreview
