import React, { Component } from 'react'
import { toast } from 'react-toastify'
import {motion} from 'framer-motion'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import $ from 'jquery'
// component
import Toaster from '../../Toaster/Toaster'
import agent from '../../../api/Api'
// css
import './SignUpForm.css'

class SignUpForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      passwordType: 'password',
      confirmPasswordType: 'password',
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      phone: '',
      ethnicity: '',
      gender: '',
      genderOthers: '',
      schoolName: '',
      schoolType: '',
      schoolGrade: '',
      city: '',
      state: '',
      address: '',
      zip: '',
      signupAs: '',
      aboutUs: '',
      challengeTeamSize: '',
      isShareYourInfo: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleInputPhoneChange = this.handleInputPhoneChange.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.handlePwdShowHide = this.handlePwdShowHide.bind(this)
  }

  handlePwdShowHide (name, type) {
    console.log(name)
    this.setState({ [name]: type === 'password' ? 'text' : 'password' })
  }

  handleInputPhoneChange (event) {
    this.setState({ phone: event })
    if (event.length > 0) {
      $('#phone').css({ border: '1px solid #ddd' })
    } else {
      $('#phone').css({ border: '1px solid red' })
    }
  }

  handleInputChange (event) {
    const { name, value } = event.target
    if (name === 'challengeTeamSize' || name === 'zip') {
      let re = /^[0-9]+$/
      if (re.test(value) || value === '') this.setState({ [name]: value })
    } else if(name === 'city') {
      let re = /^[0-9A-Za-z ]+$/
      if (re.test(value) || value === '') this.setState({ [name]: value })
    } else {
      this.setState({ [name]: value })
    }
    if (value.length > 0) {
      $('#' + name).css({ border: '1px solid #ddd' })
    } else {
      $('#' + name).css({ border: '1px solid red' })
    }

    if (name === 'signupAs') {
      if (value === 'Colleagues') {
        $('#schoolType').prop('disabled', 'disabled')
        $('#schoolGrade').prop('disabled', 'disabled')
        this.setState({ schoolType: '', schoolGrade: '' })
      } else if (value === 'Students') {
        $('#schoolType').prop('disabled', false)
        $('#schoolGrade').prop('disabled', false)
      }
    }
  }

  handleCheckboxChange (event) {
    const {name, checked} = event.target
    // console.log(name, checked)
    this.setState({[name]: checked})
  }

  handleReset () {
    this.setState({
      aboutUs: '',
      city: '',
      address: '',
      state: '',
      phone: '',
      password: '',
      confirmPassword: '',
      email: '',
      ethnicity: '',
      signupAs: '',
      gender: '',
      name: '',
      participatedYear: '',
      schoolGrade: '',
      schoolName: '',
      schoolType: '',
      challengeTeamSize: '',
      zip: '',
      isShareYourInfo: false
    })
  }

  handleSubmit () {
    $('#loader').fadeIn()
    let param = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      ethnicity: 'Prefer not to answer' , //this.state.ethnicity,
      gender: 'Prefer not to answer', //this.state.gender,
      city: this.state.city,
      state: this.state.state,
      address: this.state.address,
      zip: this.state.zip,
      signupAs: this.state.signupAs,
      participatedYear: this.state.participatedYear,
      schoolGrade: this.state.schoolGrade,
      schoolName: this.state.schoolName,
      schoolType: this.state.schoolType,
      aboutUs: this.state.aboutUs,
      challengeTeamSize: parseInt(this.state.challengeTeamSize),
      isShareYourInfo: this.state.isShareYourInfo
    }

    let notmandatory = ['ethnicity', 'aboutUs', 'isParticipated', 'isShareYourInfo']
    let count = 0
    for (var key in param) {
      if (notmandatory.indexOf(key) === -1) {
        if (param[key]) {
          $('#' + key).css({ border: '1px solid #ddd' })
          count += 1
        } else {
          $('#' + key).css({border: '1px solid red'})
        }
      }
    }

    if (param.password && param.confirmPassword) {
      if (param.password === param.confirmPassword) {
        $('#password').css({ border: '1px solid #ddd' })
        $('#confirmPassword').css({ border: '1px solid #ddd' })
      } else {
        $('#password').css({ border: '1px solid red' })
        $('#confirmPassword').css({ border: '1px solid red' })
        toast.dismiss()
        toast(<Toaster title='Teacher OnBoard' content='New and confirm password is not matched' status='error' />)
        setTimeout(function () { toast.dismiss() }, 3000)
        count -= 1
      }
    } else {
      $('#password').css({ border: '1px solid red' })
      $('#confirmPassword').css({ border: '1px solid red' })
    }

    if (param.phone) {
      $('.react-tel-input > .form-control').css({ border: '1px solid #ddd !important' })
      count += 1
    } else {
      $('.react-tel-input > .form-control').css({ border: '1px solid #ddd !important' })
    }

    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(param.email)) {
      $('#email').css({ border: '1px solid #ddd' })
    } else {
      count = 0
      $('#email').css({ border: '1px solid red' })
    }

    if (param.gender === 'Other') {
      if (this.state.genderOthers) {
        param.gender = this.state.genderOthers
        $('#genderOthers').css({ border: '1px solid #ddd' })
      } else {
        count = 0
        $('#genderOthers').css({ border: '1px solid red' })
      }
    }

    if (param.signupAs === 'Colleagues') {
      $('#schoolType').css({ border: '1px solid #ddd' })
      // $('#schoolGrade').css({ border: '1px solid #ddd' })
      count += 1
    }

    // alert(count)
    // console.log('count', count)
    // console.log('param', param)
    // $('#loader').fadeOut()
    if (count === 15) {
      agent.APILIST.teacherSignup('/teacher/create', param)
        .then(res => {
          if (res.status === 'SUCCESS') {
            $('#loader').fadeOut()
            this.handleReset()
            toast.dismiss()
            toast(<Toaster title='Teacher OnBoard' content={res.message} status='success' />)
            setTimeout(function () { toast.dismiss() }, 3000)
            this.props.handleSignUp('SUCCESS')
          }
        })
        .catch(err => {
          $('#loader').fadeOut()
          console.log(err.response.text)
          let res = JSON.parse(err.response.text)
          toast.dismiss()
          toast(<Toaster title='Teacher OnBoard' content={res.message} status='error' />)
          setTimeout(function () { toast.dismiss() }, 3000)
        })
    } else {
      window.scrollTo(0, 0)
      $('#loader').fadeOut()
    }
  }

  render () {
    return (
      <div id='signup-form'>
        <div className='signup-outer-blk noselect'>
          <a className='back-blk' href='/'>
            <img className='arrow-black-left' src={process.env.PUBLIC_URL + 'assets/images/icons/arrow-black-left.svg'} alt='arrow' />
            <p>back</p>
          </a>
          <div className='signup-blk'>
            <div className='title-blk'>
              <p className='signup-title'>sign up now</p>
              <p className='signup-desc'>Please fill out the form below to recieve a group code, free facilitator account and community page, and recieve Million Mask resources and updates. </p>
            </div>
            {/* row 1 */}
            <div className='horizontal-field-group'>
              <div className='label-input'>
                <label>name*</label>
                <br />
                <input type='text' autoFocus name='name' id='name' maxLength='25' placeholder='Enter your name' value={this.state.name} onChange={this.handleInputChange} />
              </div>
              <div className='label-input'>
                <label>email*</label>
                <br />
                <input type='text' name='email' id='email' placeholder='Enter your email address' value={this.state.email} onChange={this.handleInputChange} />
              </div>
            </div>
            {/* row 2 */}
            <div className='horizontal-field-group'>
              <div className='label-input'>
                <label>password*</label>
                <br />
                <input type={this.state.passwordType} autoFocus name='password' id='password' maxLength='25' placeholder='Enter your password' value={this.state.password} onChange={this.handleInputChange} />
                <span className='pwdShow' onClick={() => this.handlePwdShowHide('passwordType', this.state.passwordType)}>
                  {this.state.passwordType === 'text'
                    ? <img src={process.env.PUBLIC_URL + '/assets/images/icons/ic_hidden.svg'} className='show-hide-icon' alt='eye' />
                    : <img src={process.env.PUBLIC_URL + '/assets/images/icons/ic_show.svg'} className='show-hide-icon' alt='eye' />
                  }
                </span>
              </div>
              <div className='label-input'>
                <label>confirm password*</label>
                <br />
                <input type={this.state.confirmPasswordType} name='confirmPassword' id='confirmPassword' placeholder='Enter your confirm password' value={this.state.confirmPassword} onChange={this.handleInputChange} />
                <span className='pwdShow' onClick={() => this.handlePwdShowHide('confirmPasswordType', this.state.confirmPasswordType)}>
                  {this.state.confirmPasswordType === 'text'
                    ? <img src={process.env.PUBLIC_URL + '/assets/images/icons/ic_hidden.svg'} className='show-hide-icon' alt='eye' />
                    : <img src={process.env.PUBLIC_URL + '/assets/images/icons/ic_show.svg'} className='show-hide-icon' alt='eye' />
                  }
                </span>
              </div>
            </div>
            {/* row 3 */}
            <div className='horizontal-field-group'>
              <p className='sub-desc'>Help us stay in touch, and make it easy to receive update and resources. We will never share this information.</p>
              {/* <div className='label-input'>
                <label>race/ethnicity (optional)</label>
                <br />
                <input type='text' name='ethnicity' id='ethnicity' maxLength='25' placeholder='Enter your race/ethnicity (optional)' value={this.state.ethnicity} onChange={this.handleInputChange} />
              </div>
              <div className='label-input'>
                <label>gender*</label>
                <br />
                <select name='gender' id='gender' value={this.state.gender} onChange={this.handleInputChange}>
                  <option value=''>Select one...</option>
                  <option value='Male'>Male</option>
                  <option value='Female'>Female</option>
                  <option value='Other'>Other</option>                  
                  <option value='Prefer not to answer'>Prefer not to answer</option>
                </select>
              </div> */}
            </div>

            { this.state.gender === 'Other'
              ? (<motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:0.3}} >
                <div className='horizontal-field-group'>
                  <div className='label-input' />
                  <div className='label-input'>
                    <input type='text' name='genderOthers' id='genderOthers' maxLength='25' placeholder='Enter your gender' value={this.state.genderOthers} onChange={this.handleInputChange} />
                  </div>
                </div>
              </motion.div>)
              : ''
            }
            {/* row 4 */}
            <div className='horizontal-field-group'>
              <div className='label-input'>
                <label>phone*</label>
                <br />
                <PhoneInput
                  title={this.state.phone}
                  country='us'
                  id='phone'
                  name='phone'
                  ref='phone' value={this.state.phone} placeholder='Enter your phone number'
                  autoComplete='off' onChange={this.handleInputPhoneChange}
                />
              </div>

              <div className='label-input'>
                <label>What type of group are you supporting?*</label>
                <br />
                <select name='signupAs' id='signupAs' value={this.state.signupAs} onChange={this.handleInputChange}>
                  <option value=''>Select one...</option>
                  <option value='Elementary School Class'>Elementary School Class</option>
                  <option value='Middle School Class'>Middle School Class</option>
                  <option value='High School Class'>High School Class</option>
                  <option value='College Class'>College Class</option>
                  <option value='Wellness Group'>Wellness Group</option>
                  <option value='Corporate Team'>Corporate Team</option>
                  <option value='Athletic Team'>Athletic Team</option>
                  <option value='Other'>Other</option>
                </select>
              </div>
            </div>

            {this.state.signupAs
              ? (<motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:0.3}}>
                <div className='vertical-field-group'>
                  <div className='label-input'>
                    <label>How many people will participate?*</label>
                    <br />
                    <input type='text' name='challengeTeamSize' id='challengeTeamSize' maxLength='6' placeholder='Enter your team size' value={this.state.challengeTeamSize} onChange={this.handleInputChange} />
                  </div>
                </div>
              </motion.div>)
              : ''
            }

            {/* row 5 */}
            <div className='horizontal-field-group'>
              <div className='label-input'>
                <label>Organization Name*</label>
                <br />
                <input type='text' name='schoolName' id='schoolName' maxLength='50' placeholder='Enter your organization Name' value={this.state.schoolName} onChange={this.handleInputChange} />
              </div>
              <div className='label-input'>
                <label>school type*</label>
                <br />
                <select name='schoolType' id='schoolType' value={this.state.schoolType} onChange={this.handleInputChange}>
                  <option value=''>Select one...</option>
                  <option value='Public/District'>Public/District</option>
                  <option value='Independent'>Independent</option>
                  <option value='Charter'>Charter</option>
                  <option value='Other'>Other</option>
                </select>
              </div>
            </div>

            {/* row 6 */}
            <div className='horizontal-field-group'>
              {/* <div className='label-input'>
                <label>school grade level*</label>
                <br />
                <select name='schoolGrade' id='schoolGrade' value={this.state.schoolGrade} onChange={this.handleInputChange}>
                  <option value=''>Select one...</option>
                  <option value='Elementary School'>Elementary School</option>
                  <option value='Middle School'>Middle School</option>
                  <option value='High School'>High School</option>
                  <option value='Other'>Other</option>
                </select>
              </div> */}
              <div className='label-input'>
                <label>city*</label>
                <br />
                <input type='text' name='city' id='city' maxLength='25' placeholder='e.g. San Francisco' value={this.state.city} onChange={this.handleInputChange} />
              </div>
              <div className='label-input'>
                <label>state*</label>
                <br />
                <input type='text' name='state' id='state' maxLength='25' placeholder='e.g. California' value={this.state.state} onChange={this.handleInputChange} />
              </div>
            </div>

            {/* row 7 */}
            <div className='horizontal-field-group'>
              <div className='label-input'>
                <label>Address*</label>
                <br />
                <input type='text' name='address' id='address' maxLength='50' placeholder='e.g. 12/7 Down street' value={this.state.address} onChange={this.handleInputChange} />
              </div>
              <div className='label-input'>
                <label>ZIP/postal code*</label>
                <br />
                <input type='text' name='zip' id='zip' maxLength='10' placeholder='e.g. 94105' value={this.state.zip} onChange={this.handleInputChange} />
              </div>
            </div>

            {/* row 8 */}
            {/* <div className='vertical-field-group'>
              <div className='label-input'>
                <label>Have you participated in 100k masks challenge before?* </label>
                <br />
                <select name='participatedYear' id='participatedYear' value={this.state.participatedYear} onChange={this.handleInputChange}>
                  <option value=''>Select one...</option>
                  <option value='Yes, in 2017'>Yes, in 2017</option>
                  <option value='Yes, in 2018'>Yes, in 2018</option>
                  <option value='Yes, in all years'>Yes, in all years</option>
                  <option value='No'>No</option>
                </select>
              </div>
            </div> */}
            {/* row 9 */}
            <div className='vertical-field-group'>
              <div className='label-input'>
                <label>How did you hear about us? (Optional)</label>
                <br />
                <textarea rows='4' cols='50' name='aboutUs' maxLength='30' id='aboutUs' value={this.state.aboutUs} onChange={this.handleInputChange} />
              </div>
            </div>
            <div className='i-agree'>
              <div className='round'>
                <input type='checkbox' id='isShareYourInfo' name='isShareYourInfo' onChange={this.handleCheckboxChange} checked={this.state.isShareYourInfo} />
                <label htmlFor='isShareYourInfo' />
              </div>
              <label htmlFor='isShareYourInfo'>I would like to recieve Million Mask Movement email updates, news, and facilitator resources (We Will Never Share Your Information)</label>
            </div>
            <div className='submit-section'>
              <div className='submit-blk' onClick={this.handleSubmit}>
                <p className='submit-btn'>submit</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default SignUpForm
