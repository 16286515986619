import React from 'react'
import ReactDOM from 'react-dom'
import { ToastContainer } from 'react-toastify'
// import { Offline, Online } from 'react-detect-offline'
import HttpsRedirect from 'react-https-redirect'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <div>
    <HttpsRedirect>
      {/* <Online> */}
      <div id='loader'><div id='loader-img' /> </div>
      <ToastContainer
        autoClose={3000}
        position='top-right'
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable={false}
        pauseOnHover
      />
      <App />
      {/* </Online> */}
      {/* <Offline>Your offline. Please check your internet connection!</Offline> */}
    </HttpsRedirect>
  </div>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
