import React, { useState } from 'react'
import {Navigate, useNavigate, useParams} from 'react-router-dom'
// components
import SignUpForm from './SignUpForm/SignUpForm'
import SignInForm from './SignInForm/SignInForm'
import ForgotPassword from './ForgotPassword/ForgotPassword'
import ResetPassword from './ResetPassword/ResetPassword'
// css
import './Authentication.css'

const Authentication = (props) => {
    const navigate = useNavigate()

    const [signUpSuccess, setSignupSuccess] = useState(false)

    const params = useParams()
    
    let loginCheck = JSON.parse(localStorage.getItem('userInfo'))
    console.log('loginCheck', loginCheck,params)
    if (loginCheck) {
      if (loginCheck.role) navigate('/admin/dashboard')
      else navigate('/teacher/dashboard')
    }

    const onHandleSignUp = (flag)=>{
      if (flag === 'SUCCESS') {
        navigate('/teacheronboardsuccess')
      }
    }
    const onHandleSignIn = (flag, role)=> {
      if (flag === 'SUCCESS') {
        let target = role === 'teacher' ? '/teacher/dashboard' : '/admin/dashboard'
        navigate(target)
      }
    }
  
    const onHandleRedirectToSignin = (flag, role)=> {
      if (flag === 'SUCCESS') {
        let target = role === 'teacher' ? '/auth/teacher/signin' : '/auth/admin/signin' 
        navigate(target)
      }
    }
  
  return (
    <div id='teacher-on-board'>
      <div id='outer-blk' className={signUpSuccess ? 'success' : ''}>
        <div className='logo-blk'>
          <a href='/'>
            <img src={process.env.PUBLIC_URL + '/assets/images/logo.svg'} className='logo-img' alt='logo' />
          </a>
          <div className='vr-line' />
          {params.role === 'teacher'
            ? <a href='/'>
              <img src={process.env.PUBLIC_URL + '/assets/images/ic_everforward.png'} className='ever-img' alt='logo' />
            </a>
            : <p className='admin-console'>Admin console</p>
          }
        </div>
        <div id='right-side-blk'>
          {params?.componentName === 'signup'
            ? <SignUpForm handleSignUp={onHandleSignUp} role={params?.role} />
            : params?.componentName === 'signin'
              ? <SignInForm handleSignIn={onHandleSignIn} role={params?.role} />
              : params?.componentName === 'forgotpassword'
                ? <ForgotPassword handleRedirectToSignin={onHandleRedirectToSignin} role={params?.role} />
                : params?.componentName === 'resetPassword'
                  ? <ResetPassword handleRedirectToSignin={onHandleRedirectToSignin} role={params?.role} encodedString={params?.encodedString} />
                  : ''
          }
        </div>
      </div>
    </div>
  
  )
}

export default Authentication
