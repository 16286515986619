import React, { Component } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

// components
import Landing from './components/Landing/Landing'
import DrawBoard from './components/DrawBoard/DrawBoard'
import Gallery from './components/Gallery/Gallery'
import AdminDashboard from './components/AdminDashboard/AdminDashboard'
import TeacherDashboard from './components/TeacherDashboard/TeacherDashboard'
import Authentication from './components/Authentication/Authentication'
import TeacherOnBoardSuccess from './components/TeacherOnBoardSuccess/TeacherOnBoardSuccess'
import NotFound from './components/NotFound/NotFound'
import Download from './components/Download/Download'
import FuntionalComponent from './components/FunctionalComponent/FuntionalComponent'

class App extends Component {
  render () {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Landing/>} />
          <Route path='/auth/:role/:componentName' element={<Authentication/>} />
          <Route path='/auth/:role/:componentName' element={<Authentication/>} />
          <Route path='/:componentName/:role/:encodedString' element={<Authentication/>} />

          <Route path='/gallery' element={<FuntionalComponent><Gallery/></FuntionalComponent>} />
          <Route path='/teacher/dashboard' element={<FuntionalComponent><TeacherDashboard/></FuntionalComponent>} />
          <Route path='/admin/dashboard' element={<FuntionalComponent><AdminDashboard/></FuntionalComponent>} />

          <Route path='/teacheronboardsuccess' element={<FuntionalComponent><TeacherOnBoardSuccess/></FuntionalComponent>} />
          <Route path='/drawboard' element={<FuntionalComponent><DrawBoard/></FuntionalComponent>} />
          <Route path='/teacherrefer/:id' element={<FuntionalComponent><DrawBoard/></FuntionalComponent>} />

          <Route path='/admin/download' element={<FuntionalComponent><Download/></FuntionalComponent>} />
          <Route element={<NotFound/>} />
        </Routes>
      </BrowserRouter>
    )
  }
}

export default App
