import React, { Component } from 'react'
import { toast } from 'react-toastify'
import $ from 'jquery'
// component
import Toaster from '../../Toaster/Toaster'
import agent from '../../../api/Api'
// css
import './ForgotPassword.css'

class ForgotPassword extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleForgotKeyPress = this.handleForgotKeyPress.bind(this)
  }

  handleInputChange (event) {
    const { name, value } = event.target
    this.setState({ [name]: value })
    if (value.length > 0) {
      $('#' + name).css({ border: '1px solid #ddd' })
    } else {
      $('#' + name).css({ border: '1px solid red' })
    }
  }

  handleSubmit () {
    $('#loader').fadeIn()
    let param = {
      email: this.state.email
    }

    let count = 0
    for (var key in param) {
      if (param[key]) {
        $('#' + key).css({ border: '1px solid #ddd' })
        count += 1
      } else {
        $('#' + key).css({ border: '1px solid red' })
      }
    }

    let url = ''
    if (this.props.role === 'teacher') url = '/teacher/forgotPassword?email=' + this.state.email
    else url = '/admin/forgotPassword?email=' + this.state.email
    //alert(count)
    if (count === 1) {
      agent.APILIST.forgotPassword(url)
        .then(res => {
          if (res.status === 'SUCCESS') {
            $('#loader').fadeOut()
            this.props.handleRedirectToSignin('SUCCESS', this.props.role)
            toast.dismiss()
            toast(<Toaster title='Teacher Login' content={res.message} status='error' />)
            setTimeout(function () { toast.dismiss() }, 3000)
          }
        })
        .catch(err => {
          $('#loader').fadeOut()
          console.log(JSON.stringify(err.response.body))
         let response = err.response.body;
          toast.dismiss()
          toast(<Toaster title='Teacher Login' content={response.message} status='error' />)
          setTimeout(function () { toast.dismiss() }, 3000)
        })
    } else {
      window.scrollTo(0, 0)
      $('#loader').fadeOut()
    }
  }

  handleForgotKeyPress (event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleSubmit()
    }
  }

  render () {
    return (
      <div id='forgotpwd-form'>
        <div className='forgotpwd-outer-blk noselect'>
          <a className='back-blk' href={this.props.role === 'admin' ? '/auth/admin/signin' : '/auth/teacher/signin'}>
            <img className='arrow-black-left' src={process.env.PUBLIC_URL + 'assets/images/icons/arrow-black-left.svg'} alt='arrow' />
            <p>back</p>
          </a>
          <div className='forgotpwd-blk'>
            <div className='title-blk'>
              <p className='forgotpwd-title'>forgot password</p>
              <p className='forgotpwd-desc'>Don't worry, we have covered it for you!</p>
            </div>
            <div className='vertical-field-group'>
              <div className='label-input'>
                <label>email*</label>
                <br />
                <input type='text' autoFocus autoComplete='off' name='email' id='email' placeholder='Enter your email address' value={this.state.email} onChange={this.handleInputChange} onKeyPress={this.handleForgotKeyPress} />
              </div>
            </div>
            <div className='submit-section'>
              <div className='submit-blk' onClick={this.handleSubmit}>
             
                <p className='submit-btn'>Submit</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default ForgotPassword
