import React, { Component } from 'react'
import { toast } from 'react-toastify'
import $ from 'jquery'
// component
import Toaster from '../Toaster/Toaster'
import agent from '../../api/Api'
// css
import './EducatorDetail.css'

class EducatorDetail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      educatorDetail: []
    }
    this.handleGetEducator = this.handleGetEducator.bind(this)
  }

  handleGetEducator () {
    $('#loader').fadeIn()
    var url = ''
    if (this.props.educatorFlag === 'all') {
      // url = '/admin/getEducatorsDetails'
      this.setState({ educatorDetail: this.props.educatorDetailCompleteList })
    } else {
      url = '/admin/getMaskCountByTeacherId?email=' + this.props.educatorId
      agent.APILIST.getMask(url)
        .then(res => {
          if (res.status === 'SUCCESS') {
            if (this.props.educatorFlag === 'all') {
              this.setState({ educatorDetail: res.data })
            } else {
              this.setState({ educatorDetail: res.codeWithMaskCount })
            }
            $('#loader').fadeOut()
          }
        })
        .catch(err => {
          $('#loader').fadeOut()
          console.log(err.response.text)
          let res = JSON.parse(err.response.text)
          toast.dismiss()
          toast(<Toaster title='Educator' content={res.message} status='error' />)
          setTimeout(function () { toast.dismiss() }, 3000)
        })
    }
  }

  componentDidMount () {
    var loginCheck = JSON.parse(localStorage.getItem('userInfo'))
    if (loginCheck) {
      this.setState({ userInfo: loginCheck })
    }
    this.handleGetEducator(this.props.educatorFlag)
  }

  render () {
    return (
      <div id='educatorDetail'>
        <div className='educatorDetail-outer-blk noselect'>
          <div className='educatorDetail-blk'>
            <div className='title-blk'>
              <p className='educatorDetail-title'>{this.props.educatorFlag === 'all' ? 'Educators' : this.props.educatorName}</p>
            </div>
            <div className='table-outer'>
              <table>
                <thead>
                  {this.props.educatorFlag === 'all'
                    ? <tr><th>Name</th><th> <p className='educator-overlay-value-name'>No.of.Codes &amp; Masks</p></th></tr>
                    : <tr><th>Codes</th><th> <p className='educator-overlay-value-codes'>Masks</p></th></tr>}
                </thead>
                <tbody>
                  {this.props.educatorFlag === 'all'
                    ? this.state.educatorDetail.map((data, i) => (
                      <tr key={i}><td>{data.name}</td><td><p className='educator-overlay-value-name-value'>{data.codesCount} ({data.maskCount})</p></td></tr>
                    ))
                    : Object.keys(this.state.educatorDetail).map((key, i) => (
                      <tr key={i}><td>{key}</td><td><p className='educator-overlay-value-codes-value'>{this.state.educatorDetail[key]}</p></td></tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default EducatorDetail
