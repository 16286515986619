import React, { Component } from 'react'
// components
import SignUpSuccess from './SignUpSuccess/SignUpSuccess'
// css
import './TeacherOnBoardSuccess.css'

class TeacherOnBoardSuccess extends Component {
  constructor (props) {
    super(props)
    this.state = {
      signupform: true
    }
  }

  render () {
    return (
      <div id='teacher-on-board-success'>
        <div id='outer-blk'>
          <div className='logo-blk'>
            <a href='/'>
              <img src={process.env.PUBLIC_URL + '/assets/images/logo.svg'} className='logo-img' alt='logo' />
            </a>
            <div className='vr-line' />
            <a href='/'>
              <img src={process.env.PUBLIC_URL + '/assets/images/ic_everforward.png'} className='ever-img' alt='logo' />
            </a>
          </div>
          <div id='right-side-blk'>
            <SignUpSuccess />
            {/* <img src={process.env.PUBLIC_URL + '/assets/images/signupSuccessBg.png'} alt='background' /> */}
          </div>
          {/* <div id='left-side-blk'>
          </div> */}
        </div>
      </div>
    )
  }
}

export default TeacherOnBoardSuccess
