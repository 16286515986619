import React from 'react'
import { useParams } from 'react-router-dom'

function FuntionalComponent({children}) {    
  const params =  useParams()
  const match = {params}
  return (
    <React.Fragment>
        <children.type {...children?.props}{...{match}} />
    </React.Fragment>
  )
}

export default FuntionalComponent