import React, { Component} from 'react'
// import { Circle } from 'react-color'
import $ from 'jquery'
// css
import './DrawHeader.css'

class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {
      displayColorPicker: 'none'
    }
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleColorOpen = this.handleColorOpen.bind(this)
    this.handleColorClose = this.handleColorClose.bind(this)
  }
  componentDidMount () {
    $('#size-small').addClass('enable')
    $('#marker').addClass('enable')
    document.addEventListener('mousedown', this.handleColorClose)
  }

  setWrapperRef (node) {
    this.wrapperRef = node
  }

  handleColorClose (event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ displayColorPicker: 'none' })
    }
  }

  handleColorOpen () {
    this.setState({ displayColorPicker: this.state.displayColorPicker === 'none' ? 'block' : 'none' })
  }

  render () {
    return (
      <div id='draw-header'>
        <div className='header-outer-blk'>
          <div className='draw-logo-blk'>
            <a href='/'>
              <img src={process.env.PUBLIC_URL + '/assets/images/ic_logo-black.svg'} className='draw-logo-img' alt='logo' />
            </a>
            <div className='vr-line' />
            <a href='/'>
              <img src={process.env.PUBLIC_URL + '/assets/images/ic_everforward-black.png'} className='ever-img' alt='logo' />
            </a>
            <div className='mobile-draw-submit-blk noselect'>
              <div className='text-move-blk disable' id='text-move-blk'>Move Text</div>
              <img src={process.env.PUBLIC_URL + '/assets/images/icons/Info.png'} alt='info' onClick={this.props.startTour} style={{'cursor': 'pointer'}} />
              <div className='draw-submit-btn disable submit-mask' onClick={this.props.save}>submit mask</div>
            </div>
          </div>
          <div className='menu-blk'>
            <div className='tool-blk' ref='step1'>
              <img className='tool-img' id='marker' src={process.env.PUBLIC_URL + '/assets/images/icons/Marker.png'} alt='marker' />
              <img className='tool-img' id='crayon' src={process.env.PUBLIC_URL + '/assets/images/icons/Crayon.png'} alt='caron' />
              <img className='tool-img' id='eraser' src={process.env.PUBLIC_URL + '/assets/images/icons/Eraser.png'} alt='eraser' />
            </div>
            <div className='remove-blk'>
              <img className='undo-img' id='undo' src={process.env.PUBLIC_URL + '/assets/images/icons/Undo.png'} alt='eraser' />
            </div>
            <div className='size-blk'>
              <div className='size-brush' id='size-huge' />
              <div className='size-brush' id='size-large' />
              <div className='size-brush' id='size-normal' />
              <div className='size-brush' id='size-small' />
            </div>
            <div className='color-blk noselect'>
              <div className='color-picker-btn' onClick={this.handleColorOpen} />
              <div style={{display: this.state.displayColorPicker}} className='popover' ref={this.setWrapperRef}>
                <canvas id='color-picker' height='60' />
              </div>
            </div>
            <div className='text-blk noselect'>
              <div>
                <select className='font-option' defaultValue={'Arial'}>
                  <option value='Arial'>Arial</option>
                  <option value='Verdana'>Verdana</option>
                  <option value='Times New Roman'>Times New Roman</option>
                  <option value='Courier New'>Courier New</option>
                  <option value='serif'>serif</option>
                  <option value='sans-serif'>sans-serif</option>
                </select>
              </div>
              <div>
                <select className='text-size' defaultValue={'20px'}>
                  <option value='4px'>4</option>
                  <option value='6px'>6</option>
                  <option value='8px'>8</option>
                  <option value='10px'>10</option>
                  <option value='12px'>12</option>
                  <option value='14px'>14</option>
                  <option value='16px'>16</option>
                  <option value='18px'>18</option>
                  <option value='20px'>20</option>
                  <option value='22px'>22</option>
                  <option value='24px'>24</option>
                  <option value='26px'>26</option>
                  <option value='28px'>28</option>
                  <option value='30px'>30</option>
                  <option value='32px'>32</option>
                  <option value='34px'>34</option>
                  <option value='36px'>36</option>
                </select>
                <span id='bold-btn'>B</span>
                <span id='italic-btn'>I</span>
              </div>
            </div>
          </div>
          <div className='draw-submit-blk noselect'>
            <img src={process.env.PUBLIC_URL + '/assets/images/icons/Info.png'} alt='info' onClick={this.props.startTour} style={{'cursor': 'pointer'}} />
            <div className='draw-submit-btn disable submit-mask' onClick={this.props.save}>submit mask</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Header
