/* Reference - https://github.com/fkhadra/react-toastify */
import React, { Component } from 'react'
/* css */
import './Toaster.css'

export class Toaster extends Component {
  render () {
    return (
      <div id='toaster-block' className='noselect'>
        <div className='inner-blk'>
          {this.props.status === 'error'
            ? <img className='white-tick-img' src={process.env.PUBLIC_URL + 'assets/images/icons/error-close.svg'} alt='tick' />
            : <img className='white-tick-img' src={process.env.PUBLIC_URL + 'assets/images/icons/success-tick.svg'} alt='tick' />
          }
          <p className='toaster-body'>{this.props.content}</p>
        </div>
      </div>
    )
  }
};

export default Toaster
