import React, { Component} from 'react'
import { saveAs } from 'file-saver'
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share'
// css
import './MaskPreview.css'
// const
const title = '100kmasks'

class MaskPreview extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentData: '',
      showLeftArrow: true,
      showRightArrow: true,
      currentPosition: 0,
      shareUrl: ''
    }
    this.handleRightNav = this.handleRightNav.bind(this)
    this.handleLeftNav = this.handleLeftNav.bind(this)
    this.handlekeyEvent = this.handlekeyEvent.bind(this)
    this.handleSingleDownload = this.handleSingleDownload.bind(this)
  }

  componentDidMount () {
    // console.log('props', this.props)
    let showData = this.props.maskData[this.props.maskPreviewIndex]
    let url = 'https://storage.googleapis.com/ever-forward-club.appspot.com/images/' + showData.maskGuid + '.png'
    this.setState({currentData: showData, currentPosition: this.props.maskPreviewIndex, shareUrl: url})
    if (this.props.maskPreviewIndex === 0) {
      this.setState({showLeftArrow: false})
    }

    if (this.props.maskPreviewIndex === this.props.maskData.length - 1) {
      this.setState({showRightArrow: false})
    }
    document.addEventListener('keydown', this.handlekeyEvent, false);
  }

  handlekeyEvent (event) {
    // console.log(event.keyCode, event.which)
    if (event.keyCode === 27 || event.which === 27) {
      this.props.hideOverlay()
    } else if (event.keyCode === 37 || event.which === 37) {
      this.handleLeftNav()
    } else if (event.keyCode === 39 || event.which === 39) {
      this.handleRightNav()
    }

  }

  handleRightNav () {
    if (this.state.currentPosition !== this.props.maskData.length - 1) {
      let showData = this.props.maskData[this.state.currentPosition + 1]
      let url = 'https://storage.googleapis.com/ever-forward-club.appspot.com/images/' + showData.maskGuid + '.png'

      this.setState({currentPosition: this.state.currentPosition + 1, currentData: showData, shareUrl: url}, () => {
        if (this.state.currentPosition === this.props.maskData.length - 1) {
          this.setState({showRightArrow: false})
        } else {
          this.setState({showRightArrow: true})
        }
        if (this.state.currentPosition > 0) {
          this.setState({showLeftArrow: true})
        }
      })

    } 
  }

  handleLeftNav () {
    if (this.state.currentPosition > 0) {
      let showData = this.props.maskData[this.state.currentPosition - 1]
      let url = 'https://storage.googleapis.com/ever-forward-club.appspot.com/images/' + showData.maskGuid + '.png'
      this.setState({currentPosition: this.state.currentPosition - 1, currentData: showData, shareUrl: url}, function() {
        if (this.state.currentPosition === 0) {
          this.setState({showLeftArrow: false})
        } else {
          this.setState({showLeftArrow: true})
        }
        if (this.state.currentPosition < this.props.maskData.length - 1) {
          this.setState({showRightArrow: true})
        }
      })
    }    
  }

  handleSingleDownload (guid) {
    let url = 'https://storage.googleapis.com/ever-forward-club.appspot.com/images/' + guid + '.png'
    saveAs(url, guid + '.png')
  }

  render () {
    return (
      <div id='maskpreview'>
        <div className='navigation-outer noselect'>
          <div className='navigation-blk'>
            <div className={this.state.showLeftArrow ? 'navigation-btn' : 'navigation-btn disable'} onClick={this.handleLeftNav}><img src={process.env.PUBLIC_URL + '/assets/images/icons/arrow-red-left.png'} alt='previous' /></div>
            <div className={this.state.showRightArrow ? 'navigation-btn' : 'navigation-btn disable'} onClick={this.handleRightNav}><img src={process.env.PUBLIC_URL + '/assets/images/icons/arrow-red-right.png'} alt='next' /></div>
          </div>
          <div className='pre-mask-blk'>            
            <div className='mask-top'>
              <div className='share-outer'>
                <div className='share-blk'>
                  <div className='share-btn'>
                    <FacebookShareButton url={this.state.shareUrl} quote={title} className='button'>
                      <FacebookIcon size={30} round />
                    </FacebookShareButton>
                    <TwitterShareButton url={this.state.shareUrl} quote={title} className='button'>
                      <TwitterIcon size={30} round />
                    </TwitterShareButton>
                  </div>
                </div>
              </div>
              <img src={'https://storage.googleapis.com/ever-forward-club.appspot.com/images/' + this.state.currentData.maskGuid + '.png'} alt='mask' />
            </div>
            <div className='mask-bottom'>
              <div className='mask-info'><span>{this.state.currentData.age} Yrs</span> | <span>{this.state.currentData.gender}</span> | <span>{this.state.currentData.city}</span></div>
              <div className='download-btn' onClick={() => this.handleSingleDownload(this.state.currentData.maskGuid)}>
                <img className='download-img' src={process.env.PUBLIC_URL + '/assets/images/icons/download-solid.png'} alt='download' />
                <span className='download-label'>Download</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MaskPreview

