import React, { Component } from 'react'
import $ from 'jquery'
// import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
// component
import MaskPreview from '../MaskPreview/MaskPreview'
import DrawPreview from '../DrawPreview/DrawPreview'
import Subscribe from '../Subscribe/Subscribe'
import MaskSuccess from '../MaskSuccess/MaskSuccess'
import EditProfile from '../EditProfile/EditProfile'
import EducatorDetail from '../EducatorDetail/EducatorDetail'
import ChangePassword from '../ChangePassword/ChangePassword'
import MyCode from '../MyCode/MyCode'
import Help from '../Help/Help'

import './Overlay.css'

class Overlay extends Component {
  componentName () {
    switch (this.props.typeOfComponent) {
      case 'MaskPreview':
        return <MaskPreview hideOverlay={this.props.hideOverlay} maskData={this.props.maskData} maskPreviewIndex={this.props.maskPreviewIndex}/>
      case 'DrawPreview':
        return <DrawPreview param={this.props.param} hideOverlay={this.props.hideOverlay} />
      case 'Subscribe':
        return <Subscribe param={this.props.param} hideOverlay={this.props.hideOverlay} />
      case 'MaskSuccess':
        return <MaskSuccess hideOverlay={this.props.hideOverlay} param={this.props.param} />
      case 'EducatorDetail':
        return <EducatorDetail hideOverlay={this.props.hideOverlay} educatorId={this.props.educatorId} educatorName={this.props.educatorName} educatorFlag={this.props.educatorFlag} educatorDetailCompleteList={this.props.educatorDetailCompleteList} />
      case 'EditProfile':
        return <EditProfile hideOverlay={this.props.hideOverlay} param={this.props.param} />
      case 'ChangePassword':
        return <ChangePassword hideOverlay={this.props.hideOverlay} param={this.props.param} />
      case 'MyCodes':
        return <MyCode hideOverlay={this.props.hideOverlay} param={this.props.param} />
      case 'Help':
        return <Help hideOverlay={this.props.hideOverlay} />
      default :
        return null
    }
  }

  // hide background scroll
  componentDidMount () { 
    // disableBodyScroll('body') 
    $('body').css('overflow-y', 'hidden')
    console.log('model')
  }

  // reset background scroll
  componentWillUnmount () { 
    // clearAllBodyScrollLocks() 
    $('body').css('overflow-y', 'scroll')
  }

  render () {
    const component = this.componentName()
    return (
      <div id='overlay'>
        <div className={'overlay-block ' + this.props.size + ' ' + this.props.typeOfComponent}>
          {this.props.typeOfComponent === 'MaskPreview' || this.props.typeOfComponent === 'MyCodes' || this.props.typeOfComponent === 'EducatorDetail'
            ? (<div className='close-outer'>
              <div className='close-blk' onClick={() => this.props.hideOverlay()}><img className='close-img' alt='close' src='/assets/images/icons/Close.png' /></div>
            </div>
            )
            : ''
          }
          <div className='overlay-body'>
            {component}
          </div>
        </div>
      </div>
    )
  }
}

export default Overlay
