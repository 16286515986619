
import React, { Component } from 'react'
import './NotFound.css'

class NotFound extends Component {
  render () {
    return (
      <div id='pnf-container'>
        <div className='pnf-block'>
          <div className='pnf-title'>404</div>
          <div className='pnf-desc'>The page you are looking for isn't here <a className='pnf-btn' href='/'>Go Back to Home</a></div> 
        </div>
      </div>
    )
  }
}

export default NotFound
